import Icon from '@/components/Icons/Icon'

Icon.register({
  'menu-add': {
    'width': 24,
    'height': 24,
    'raw': `<line fill="none" stroke-miterlimit="10" x1="12" y1="2" x2="12" y2="22" />
      <line fill="none" stroke-miterlimit="10" x1="22" y1="12" x2="2" y2="12" />`,
    'g': {
      'stroke': 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-width': '2'
    }
  }
})
