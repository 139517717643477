var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.task)?_c('el-form',{ref:"form",staticClass:"mo-task-activity",attrs:{"model":_vm.form,"label-width":_vm.formLabelWidth}},[_c('div',{ref:"graphicBox",staticClass:"graphic-box"},[(_vm.graphicWidth > 0)?_c('mo-task-graphic',{attrs:{"outerWidth":_vm.graphicWidth,"bitfield":_vm.task.bitfield}}):_vm._e()],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-progress-info')}: `}},[_c('div',{staticClass:"form-static-value",staticStyle:{"overflow":"hidden"}},[_c('el-row',{attrs:{"gutter":12}},[_c('el-col',{attrs:{"span":18}},[_c('div',{staticClass:"progress-wrapper"},[_c('mo-task-progress',{attrs:{"completed":Number(_vm.task.completedLength),"total":Number(_vm.task.totalLength),"status":_vm.taskStatus}})],1)]),_vm._v(" "),_c('el-col',{attrs:{"span":5}},[_vm._v("\n          "+_vm._s(_vm.percent)+"\n        ")])],1)],1)]),_vm._v(" "),_c('el-form-item',[_c('div',{staticClass:"form-static-value"},[_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.completedLength,2)))]),_vm._v(" "),(_vm.task.totalLength > 0)?_c('span',[_vm._v(" / "+_vm._s(_vm._f("bytesToSize")(_vm.task.totalLength,2)))]):_vm._e(),_vm._v(" "),(_vm.isActive && _vm.remaining > 0)?_c('span',{staticClass:"task-time-remaining"},[_vm._v("\n        "+_vm._s(_vm._f("timeFormat")(_vm.remaining,{
            prefix: _vm.$t('task.remaining-prefix'),
            i18n: {
              'gt1d': _vm.$t('app.gt1d'),
              'hour': _vm.$t('app.hour'),
              'minute': _vm.$t('app.minute'),
              'second': _vm.$t('app.second')
            }
          }))+"\n      ")]):_vm._e()])]),_vm._v(" "),(_vm.isBT)?_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-num-seeders')}: `}},[_c('div',{staticClass:"form-static-value"},[_vm._v("\n      "+_vm._s(_vm.task.numSeeders)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-connections')}: `}},[_c('div',{staticClass:"form-static-value"},[_vm._v("\n      "+_vm._s(_vm.task.connections)+"\n    ")])]),_vm._v(" "),_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-download-speed')}: `}},[_c('div',{staticClass:"form-static-value"},[_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.downloadSpeed))+"/s")])])]),_vm._v(" "),(_vm.isBT)?_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-upload-speed')}: `}},[_c('div',{staticClass:"form-static-value"},[_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.uploadSpeed))+"/s")])])]):_vm._e(),_vm._v(" "),(_vm.isBT)?_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-upload-length')}: `}},[_c('div',{staticClass:"form-static-value"},[_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.uploadLength)))])])]):_vm._e(),_vm._v(" "),(_vm.isBT)?_c('el-form-item',{attrs:{"label":`${_vm.$t('task.task-ratio')}: `}},[_c('div',{staticClass:"form-static-value"},[_vm._v("\n      "+_vm._s(_vm.ratio)+"\n    ")])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }