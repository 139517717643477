export default {
  'basic': 'Basique',
  'advanced': 'Avancé',
  'lab': 'Labo',
  'save': 'Sauver et appliquer',
  'save-success-message': 'Enregistrer les préférences avec succès',
  'save-fail-message': 'La sauvegarde des préférences a échoué',
  'discard': 'Annuler les changement',
  'startup': 'Démarrage',
  'open-at-login': 'Ouvrir à la connexion',
  'keep-window-state': 'Restaurez la taille et la position de la fenêtre',
  'auto-resume-all': 'Reprendre les tâches non terminées',
  'default-dir': 'Répertoire par défaut',
  'mas-default-dir-tips': 'En raison des restrictions d\'autorisations du bac à sable de l\'App Store, il est recommandé de définir le répertoire de téléchargement par défaut sur le répertoire Téléchargements.',
  'transfer-settings': 'Transmission',
  'transfer-speed-upload': 'Limite de téléversement',
  'transfer-speed-download': 'Limite de téléchargement',
  'transfer-speed-unlimited': 'Illimité',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Enregistrer le lien de l\'aimant en tant que fichier torrent',
  'bt-auto-download-content': 'Télécharger automatiquement l\'aimant et le contenu du torrent',
  'bt-force-encryption': 'Forcer le chiffrement de BT',
  'keep-seeding': 'Continuez à semer jusqu\'à ce que vous l\'arrêtiez manuellement',
  'seed-ratio': 'Ratio de semences',
  'seed-time': 'Temps de semence',
  'seed-time-unit': 'minutes',
  'task-manage': 'Tâches',
  'max-concurrent-downloads': 'Nombre de tâches active au maximum',
  'max-connection-per-server': 'Nombre maximum de connexions par serveurs',
  'new-task-show-downloading': 'Montrer automatiquement les téléchargements après l\'ajout d\'une tâche',
  'no-confirm-before-delete-task': 'Aucune confirmation n\'est requise avant de supprimer la tâche',
  'continue': 'Continuer',
  'task-completed-notify': 'Notifier à la fin d\'un téléchargement',
  'auto-purge-record': 'Purger l\'historique de téléchargement lorsque vous quittez l\'application',
  'ui': 'UI',
  'appearance': 'Mode d\'apparence',
  'theme-auto': 'Automatique',
  'theme-light': 'Clair',
  'theme-dark': 'Sombre',
  'auto-hide-window': 'Masquer automatiquement les fenêtres',
  'run-mode': 'Courir comme',
  'run-mode-standard': 'Application standard',
  'run-mode-tray': 'Application de la zone de notification',
  'run-mode-hide-tray': 'Masquer l\'application de la barre d\'état système',
  'tray-speedometer': 'La barre de menus affiche la vitesse en temps réel',
  'show-progress-bar': 'Afficher la barre de progression du téléchargement',
  'language': 'Langues',
  'change-language': 'Changer la langue',
  'hide-app-menu': 'Cacher le menu de l\'application (Windows & Linux uniquement)',
  'proxy': 'Proxy',
  'enable-proxy': 'Activer le Proxy',
  'proxy-bypass-input-tips': 'Contourner les paramètres de proxy pour ces hôtes et domaines, un par ligne',
  'proxy-scope-download': 'Télécharger',
  'proxy-scope-update-app': 'Mettre à jour l\'application',
  'proxy-scope-update-trackers': 'Mettre à jour les trackers',
  'proxy-tips': 'Afficher le manuel du proxy',
  'bt-tracker': 'Serveurs Tracker',
  'bt-tracker-input-tips': 'Serveur de suivi, un par ligne',
  'bt-tracker-tips': 'Recommander:',
  'sync-tracker-tips': 'Sync',
  'auto-sync-tracker': 'Mettre à jour automatiquement la liste des trackers chaque jour',
  'port': 'Ports d\'écoute',
  'bt-port': 'Ports d\'écoute BT',
  'dht-port': 'Ports d\'écoute DHT',
  'security': 'Sécurité',
  'rpc': 'RPC',
  'rpc-listen-port': 'Port d\'écoute RPC',
  'rpc-secret': 'RPC Secret',
  'rpc-secret-tips': 'Voir le manuel secret RPC',
  'developer': 'Développeur',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Mock User-Agent',
  'aria2-conf-path': 'Chemin intégré de aria2.conf',
  'app-log-path': 'Chemin des logs',
  'download-session-path': 'Chemin de la session de téléchargement',
  'factory-reset': 'Réinitialisation',
  'factory-reset-confirm': 'Êtes vous sûr de vouloir réinitialiser les paramètres',
  'lab-warning': '⚠️ Activer les fonctionalités labo peut causer des crash ou la perte de données !',
  'download-protocol': 'Protocole',
  'protocols-default-client': 'Définir comme client par défaut pour les protocoles suivants',
  'protocols-magnet': 'Aimant [ magnet:// ]',
  'protocols-thunder': 'Tonnerre [ thunder:// ]',
  'browser-extensions': 'Extensions',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Fourni par la communauté, ',
  'baidu-exporter-help': 'Cliquez ici pour voir l\'utilisation',
  'auto-update': 'Mettre à jour',
  'auto-check-update': 'Mise à jour automatique',
  'last-check-update-time': 'dernier contrôle la mise à jour du temps',
  'not-saved': 'Préférences non enregistrées',
  'not-saved-confirm': 'Les préférences modifiées seront perdues, êtes-vous sûr de partir ?'
}
