export default {
  'basic': 'Βασικές',
  'advanced': 'Προχωρημένες',
  'lab': 'Εργαστήριο',
  'save': 'Αποθήκευση & Εφαρμογή',
  'save-success-message': 'Οι προτιμήσεις αποθηκεύτηκαν επιτυχώς',
  'save-fail-message': 'Η αποθήκευση των προτιμήσεων απέτυχε',
  'discard': 'Απόρριψη',
  'startup': 'Εκκίνηση',
  'open-at-login': 'Εκκίνηση κατά τη σύνδεση',
  'keep-window-state': 'Αποθήκευσε το μέγεθος και τη θέση του παραθύρου κατά την έξοδο',
  'auto-resume-all': 'Συνέχισε αυτόματα όλες τις μη τελειωμένες λήψεις',
  'default-dir': 'Προεπιλεγμένος φάκελος λήψεων',
  'mas-default-dir-tips': 'Λόγω των περιορισμών δικαιωμάτων του App Store,ο προεπιλεγμένος φάκελος λήψεων συστήνεται να είναι το ~/Downloads',
  'transfer-settings': 'Μετάδοση',
  'transfer-speed-upload': 'Όριο αποστολής',
  'transfer-speed-download': 'Όριο λήψης',
  'transfer-speed-unlimited': 'Χωρίς όριο',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Αποθηκεύστε το σύνδεσμο μαγνήτη ως αρχείο torrent',
  'bt-auto-download-content': 'Αυτόματη λήψη περιεχομένου μαγνήτη και χείμαρξης',
  'bt-force-encryption': 'Εξαναγκασμός κρυπτογράφησης BT',
  'keep-seeding': 'Συνεχίστε να σπέρνετε μέχρι να το σταματήσετε χειροκίνητα',
  'seed-ratio': 'Αναλογία σπόρου',
  'seed-time': 'Χρόνος σπόρου',
  'seed-time-unit': 'λεπτά',
  'task-manage': 'Διαχείριση εργασιών',
  'max-concurrent-downloads': 'Μέγιστες ενεργές Εργασίες',
  'max-connection-per-server': 'Μέγιστες συνδέσεις ανά Σέρβερ',
  'new-task-show-downloading': 'Δείξε αυτόματα τη λήψη μετά τη πρόσθεση της εργασίας',
  'no-confirm-before-delete-task': 'Δεν χρειάζεται επιβεβαίωση πριν τη διαγραφή της εργασίας',
  'continue': 'Συνέχεια',
  'task-completed-notify': 'Ειδοποίηση μόλις τελειώσει η λήψη',
  'auto-purge-record': 'Καθάρισε αυτόματα τη λίστα λήψεων κατά το κλείσιμο της εφαρμογής',
  'ui': 'UI',
  'appearance': 'Θέμα',
  'theme-auto': 'Αυτόματο',
  'theme-light': 'Ανοιχτό',
  'theme-dark': 'Σκούρο',
  'auto-hide-window': 'Αυτόματη απόκρυψη του παραθύρου',
  'run-mode': 'Εκτέλεση ως',
  'run-mode-standard': 'Κανονική εφαρμογή',
  'run-mode-tray': 'Εφαρμογή περιοχής ειδοποιήσεων',
  'run-mode-hide-tray': 'Απόκρυψη εφαρμογής της περιοχής ειδοποιήσεων',
  'tray-speedometer': 'Δείξε τη ταχύτητα στο μενου',
  'show-progress-bar': 'Εμφάνιση μπάρας προόδου λήψης',
  'language': 'Γλώσσα',
  'change-language': 'Αλλαγή Γλώσσας',
  'hide-app-menu': 'Κρύψε το μενού της εφαρμογής (μόνο για Windows & Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Χρησιμοίησε  Proxy',
  'proxy-bypass-input-tips': 'Μη χρησιμοποιήσεις  ρυθμίσεις proxy για αυτούς τους Hosts και τα Domains, ένα ανά γραμμή',
  'proxy-scope-download': 'Λήψη',
  'proxy-scope-update-app': 'Ενημέρωση εφαρμογής',
  'proxy-scope-update-trackers': 'Ενημέρωση Trackers',
  'proxy-tips': 'Εμφάνιση εγχειριδίου Proxy',
  'bt-tracker': 'Tracker Servers',
  'bt-tracker-input-tips': 'Tracker servers, ένα ανά γραμμή',
  'bt-tracker-tips': 'Συνιστόνται: ',
  'sync-tracker-tips': 'Συγχρονισμός',
  'auto-sync-tracker': 'Ανανέωση της λίστας των tracker κάθε μέρα αυτόματα',
  'port': 'Ενεργές θύρες',
  'bt-port': 'Ενεργή θύρα BT',
  'dht-port': 'Ενεργή θύρα DHT',
  'security': 'Ασφάλεια',
  'rpc': 'RPC',
  'rpc-listen-port': 'Θύρα Ακρόασης RPC',
  'rpc-secret': 'Μυστικό για το RPC',
  'rpc-secret-tips': 'Εμφάνιση εγχειριδίου για το Μυστικό RPC',
  'developer': 'Προγραμματιστής',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Πλαστό User-Agent',
  'aria2-conf-path': 'Ενσωματωμένη διαδρομή aria2.conf',
  'app-log-path': 'Διαδρομή για το αρχείο log της εφαρμογής',
  'download-session-path': 'Διαδρομή λήψεων για αυτή τη συνεδρία',
  'factory-reset': 'Επαναφορά στις εργοστασιακές ρυθμίσεις',
  'factory-reset-confirm': 'Είστε σίγουροι ότι θέλετε να επαναφέρετε τις εργοστασιακές ρυθμίσεις;',
  'lab-warning': '⚠️ Η ενεργοποίηση ρυθμίσεων Εργαστηρίου μπορεί να οδηγήσει σε κολλήματα στην εφαρμογή ή απώλεια δεδομένων, αποφασίστε με δικό σας ρίσκο',
  'download-protocol': 'Πρωτόκολλα',
  'protocols-default-client': 'Ρύθμιση ως προεπιλεγμένο πρόγραμμα για τα παρακάτω πρωτόκολλα',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Επεκτάσεις',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Παρέχεται από την κοινότητα, ',
  'baidu-exporter-help': 'Κάντε κλικ εδώ για χρήση',
  'auto-update': 'Αυτόματη ενημέρωση',
  'auto-check-update': 'Αυτόματος έλεγχος για ενημερώσεις',
  'last-check-update-time': 'Τελευταία φορά που έγινε έλεγχος για Ενημερώσεις',
  'not-saved': 'Οι προτιμήσεις δεν αποθηκεύτηκαν',
  'not-saved-confirm': 'Οι αλλαγμένες προτιμήσεις θα χαθούν, είστε σίγουροι ότι θα φύγετε;'
}
