var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"task-progress-info"},[_c('el-col',{staticClass:"task-progress-info-left",attrs:{"xs":12,"sm":7,"md":6,"lg":6}},[(_vm.task.completedLength > 0 || _vm.task.totalLength > 0)?_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.completedLength,2)))]),_vm._v(" "),(_vm.task.totalLength > 0)?_c('span',[_vm._v(" / "+_vm._s(_vm._f("bytesToSize")(_vm.task.totalLength,2)))]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.isActive)?_c('el-col',{staticClass:"task-progress-info-right",attrs:{"xs":12,"sm":17,"md":18,"lg":18}},[_c('div',{staticClass:"task-speed-info"},[(_vm.isBT)?_c('div',{staticClass:"task-speed-text"},[_c('i',[_c('mo-icon',{attrs:{"name":"arrow-up","width":"10","height":"14"}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.uploadSpeed))+"/s")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"task-speed-text"},[_c('i',[_c('mo-icon',{attrs:{"name":"arrow-down","width":"10","height":"14"}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.task.downloadSpeed))+"/s")])]),_vm._v(" "),(_vm.remaining > 0)?_c('div',{staticClass:"task-speed-text hidden-sm-and-down"},[_c('span',[_vm._v("\n          "+_vm._s(_vm._f("timeFormat")(_vm.remaining,{
              prefix: _vm.$t('task.remaining-prefix'),
              i18n: {
                'gt1d': _vm.$t('app.gt1d'),
                'hour': _vm.$t('app.hour'),
                'minute': _vm.$t('app.minute'),
                'second': _vm.$t('app.second')
              }
            }))+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.isBT)?_c('div',{staticClass:"task-speed-text hidden-sm-and-down"},[_c('i',[_c('mo-icon',{attrs:{"name":"magnet","width":"10","height":"14"}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.task.numSeeders))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"task-speed-text hidden-sm-and-down"},[_c('i',[_c('mo-icon',{attrs:{"name":"node","width":"10","height":"14"}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.task.connections))])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }