export default {
  'task-list': 'Taken',
  'add-task': 'Taak toevoegen',
  'about': 'Over Motrix',
  'preferences': 'Voorkeuren...',
  'check-for-updates': 'Naar updates zoeken...',
  'check-updates-now': 'Jetzt prüfen',
  'checking-for-updates': 'Updates zoeken ...',
  'check-for-updates-title': 'Naar updates zoeken...',
  'update-available-message': 'Er is een nieuwe versie van Motrix beschikbaar, nu updaten?',
  'update-not-available-message': 'U heeft de laatste versie!',
  'update-downloaded-message': 'Klaar om te installeren...',
  'update-error-message': 'Update fout',
  'engine-damaged-message': 'De engine is beschadigd, opnieuw installeren a.u.b. :(',
  'engine-missing-message': 'De engine mist, opnieuw installeren a.u.b. :(',
  'system-error-title': 'Systeemfout',
  'system-error-message': 'Applicatie kon niet worden gestart: {{message}}',
  'hide': 'Motrix verbergen',
  'hide-others': 'Verberg anderen',
  'unhide': 'Laat alles zien',
  'show': 'Laat Motrix zien',
  'quit': 'Motrix afsluiten',
  'under-development-message': 'Sorry, deze functie is nog in ontwikkeling...',
  'yes': 'Ja',
  'no': 'Nee',
  'save': 'Opslaan',
  'reset': 'Resetten',
  'cancel': 'Annuleren',
  'submit': 'Verzenden',
  'gt1d': '> 1 dag',
  'hour': 'u',
  'minute': 'm',
  'second': 's'
}
