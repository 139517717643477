export default {
  'basic': 'أساسية',
  'advanced': 'متقدمة',
  'lab': 'المختبر',
  'save': 'حفظ وتطبيق',
  'save-success-message': 'تم حفظ التفضيلات بنجاح',
  'save-fail-message': 'فشل في حفظ التفضيلات',
  'discard': 'تجاهل',
  'startup': 'التشغيل',
  'open-at-login': 'الفتح عند تسجيل الدخول',
  'keep-window-state': 'المحافظة على حجم وموضع النافذة عند الخروج',
  'auto-resume-all': 'استئناف جميع التحميلات غير المكتملة تلقائيًا',
  'default-dir': 'المسار الافتراضي',
  'mas-default-dir-tips': '~/Downloads نظراً لقيود آلية تحديد الصلاحيات في متجر أبل، يستحسن ضبط مجلد التحميل الافتراضي إلى',
  'transfer-settings': 'النقل',
  'transfer-speed-upload': 'حد سرعة الرفع',
  'transfer-speed-download': 'حد سرعة التحميل',
  'transfer-speed-unlimited': 'غير محدود',
  'bt-settings': 'بت تورنت',
  'bt-save-metadata': 'احفظ البيانات الوصفية للرابط المغناطيسي كملف تورنت',
  'bt-auto-download-content': 'قم تلقائيا بتنزيل محتوى المغناطيس والسيل',
  'bt-force-encryption': 'تشفير BT الإجباري',
  'keep-seeding': 'الحفاظ على البزرة حتى يتم ايقافها يدويًا',
  'seed-ratio': 'نسبة البذرة',
  'seed-time': 'وقت البذرة',
  'seed-time-unit': 'دقائق',
  'task-manage': 'إدارة التحميلات',
  'max-concurrent-downloads': 'الحد الأقصى من التحميلات النشطة',
  'max-connection-per-server': 'الحد الأقصى من الاتصالات لكل خادم',
  'new-task-show-downloading': 'إظهار التحميل تلقائيًا بعد إضافة التحميل',
  'no-confirm-before-delete-task': 'لاتطلب التأكيد قبل حذف التحميل',
  'continue': 'الإستمرارية',
  'task-completed-notify': 'إشعار بعد اكتمال التحميل',
  'auto-purge-record': 'مسح سجلات التحميل تلقائيًا عند الخروج من التطبيق',
  'ui': 'الواجهة',
  'appearance': 'المظهر',
  'theme-auto': 'تلقائي',
  'theme-light': 'فاتح',
  'theme-dark': 'داكن',
  'auto-hide-window': 'إخفاء النافذة تلقائيًا',
  'run-mode': 'تشغيل كـ',
  'run-mode-standard': 'التطبيق القياسي',
  'run-mode-tray': 'تطبيق العلبة',
  'run-mode-hide-tray': 'إخفاء تطبيق العلبة',
  'tray-speedometer': 'يعرض درج شريط القوائم السرعة في الوقت الفعلي',
  'show-progress-bar': 'عرض شريط تقدم التنزيل',
  'language': 'اللغة',
  'change-language': 'تغيير اللغة',
  'hide-app-menu': 'إخفاء قائمة التطبيقات (Windows و Linux فقط)',
  'proxy': 'الخادم الوسيط',
  'enable-proxy': 'تفعيل الخادم الوسيط',
  'proxy-bypass-input-tips': 'تخطي إعدادات الخادم الوسيط لهذه المضيفات والمجالات، واحدة لكل سطر',
  'proxy-scope-download': 'تنزيل',
  'proxy-scope-update-app': 'تحديث التطبيق',
  'proxy-scope-update-trackers': 'تحديث المتتبعات',
  'proxy-tips': 'عرض دليل الخادم الوسيط',
  'bt-tracker': 'خوادم التعقب',
  'bt-tracker-input-tips': 'خوادم التعقب، واحدة لكل سطر',
  'bt-tracker-tips': 'مستحسن: ',
  'sync-tracker-tips': 'مزامنة',
  'auto-sync-tracker': 'تحديث قائمة التعقب تلقائيًا كل يوم',
  'port': 'منافذ الاستماع',
  'bt-port': 'منفذ BT للاستماع',
  'dht-port': 'منفذ DHT للاستماع',
  'security': 'الحماية',
  'rpc': 'RPC',
  'rpc-listen-port': 'منفذ استماع RPC',
  'rpc-secret': 'رمز RPC السري',
  'rpc-secret-tips': 'عرض دليل رمز RPC السري',
  'developer': 'المطور',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'وكيل مستخدم وهمي',
  'aria2-conf-path': 'مسار aria2.conf المدمج',
  'app-log-path': 'مسار سجلات التطبيق',
  'download-session-path': 'مسار التحميلات',
  'session-reset': 'إعادة التحميل',
  'session-reset-confirm': 'هل أنت متأكد أنك تريد إعادة تحميله؟',
  'factory-reset': 'العودة إلى الإعدادات الافتراضية',
  'factory-reset-confirm': 'هل أنت متأكد من العودة إلى الإعدادات الافتراضية؟',
  'lab-warning': '⚠️ قد يؤدي تمكين إضافات المختبر إلى تعطل التطبيق أو فقدان البيانات، لذا قرر على مسؤوليتك الخاصة!',
  'download-protocol': 'البروتوكولات',
  'protocols-default-client': 'تعيين كعميل افتراضي للبروتوكولات التالية',
  'protocols-magnet': 'المغناطيس [ magnet:// ]',
  'protocols-thunder': 'الرعد [ thunder:// ]',
  'browser-extensions': 'الإضافات',
  'baidu-exporter': 'مُصدر بايدو (Baidu)',
  'browser-extensions-tips': 'مقدمة من المجتمع، ',
  'baidu-exporter-help': 'اضغط هنا لبدء الاستخدام',
  'auto-update': 'التحديث التلقائي',
  'auto-check-update': 'تحقق تلقائيًا من التحديث',
  'last-check-update-time': 'آخر مرة تم التحقق من وجود تحديثات',
  'not-saved': 'التفضيلات غير محفوظة',
  'not-saved-confirm': 'ستفقد التفضيلات التي تم تغييرها ، هل أنت متأكد من المغادرة؟'
}
