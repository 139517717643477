export default {
  'basic': 'Cơ bản',
  'advanced': 'Nâng cao',
  'lab': 'Phòng thí nghiệm',
  'save': 'Lưu & Áp dụng',
  'save-success-message': 'Lưu cài đặt thành công',
  'save-fail-message': 'Lưu cài đặt thất bại',
  'discard': 'Loại bỏ',
  'startup': 'Khởi động',
  'open-at-login': 'Mở khi đăng nhập',
  'keep-window-state': 'Giữ kích thước và vị trí của cửa sổ khi thoát',
  'auto-resume-all': 'Tự động tiếp tục tất cả các tác vụ chưa hoàn thành',
  'default-dir': 'Đường dẫn mặc định',
  'mas-default-dir-tips': 'Do các hạn chế cấp phép sandbox của App Store, thư mục tải xuống mặc định được khuyến nghị đặt tại ~/Downloads',
  'transfer-settings': 'Tốc độ truyền',
  'transfer-speed-upload': 'Giới hạn Tải lên',
  'transfer-speed-download': 'Giới hạn Tải về',
  'transfer-speed-unlimited': 'Không giới hạn',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Lưu liên kết magnet dưới dạng tệp torrent',
  'bt-auto-download-content': 'Tự động tải xuống nam châm và nội dung torrent',
  'bt-force-encryption': 'Bắt BT mã hóa đầy đủ',
  'keep-seeding': 'Tiếp tục seed cho đến khi dừng lại theo cách thủ công',
  'seed-ratio': 'Tỷ lệ seed',
  'seed-time': 'Thời gian seed',
  'seed-time-unit': 'phút',
  'task-manage': 'Quản lý Tác vụ',
  'max-concurrent-downloads': 'Số tác vụ đang hoạt động tối đa',
  'max-connection-per-server': 'Số kết nối tối đa trên mỗi máy chủ',
  'new-task-show-downloading': 'Tự động hiển thị tải xuống sau khi thêm tác vụ',
  'no-confirm-before-delete-task': 'Không cần xác nhận trước khi xóa tác vụ',
  'continue': 'Tiếp tục',
  'task-completed-notify': 'Thông báo sau khi tải xuống hoàn tất',
  'auto-purge-record': 'Tự động xoá hồ sơ tải xuống khi thoát khỏi ứng dụng',
  'ui': 'UI',
  'appearance': 'Giao diện',
  'theme-auto': 'Tự động',
  'theme-light': 'Nền sáng',
  'theme-dark': 'Nền tối',
  'auto-hide-window': 'Tự động ẩn cửa sổ',
  'run-mode': 'Chạy như',
  'run-mode-standard': 'Ứng dụng Tiêu chuẩn',
  'run-mode-tray': 'Ứng dụng khay thông báo',
  'run-mode-hide-tray': 'Ẩn ứng dụng khay hệ thống',
  'tray-speedometer': 'Thanh menu hiển thị tốc độ thời gian thực',
  'show-progress-bar': 'Hiển thị thanh tiến trình tải xuống',
  'language': 'Ngôn ngữ',
  'change-language': 'Thay đổi Ngôn ngữ',
  'hide-app-menu': 'Ẩn thanh Menu (Chỉ Windows & Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Bật Proxy',
  'proxy-bypass-input-tips': 'Bỏ qua cài đặt proxy cho các Máy chủ và tên miền này, mỗi cái một dòng',
  'proxy-scope-download': 'Tải về',
  'proxy-scope-update-app': 'Cập nhật ứng dụng',
  'proxy-scope-update-trackers': 'Cập nhật theo dõi',
  'proxy-tips': 'Xem Proxy Thủ Công',
  'bt-tracker': 'Máy Chủ Tracker',
  'bt-tracker-input-tips': 'Máy chủ theo dõi, mỗi thông tin trên một dòng',
  'bt-tracker-tips': 'Khuyên dùng: ',
  'sync-tracker-tips': 'Đồng bộ',
  'auto-sync-tracker': 'Tự động cập nhật danh sách tracker mỗi ngày',
  'port': 'Cổng giao tiếp cuối',
  'bt-port': 'Cổng giao tiếp BT cuối',
  'dht-port': 'Cổng giao tiếp DHT cuối',
  'security': 'Bảo mật',
  'rpc': 'RPC',
  'rpc-listen-port': 'Cổng Nghe RPC',
  'rpc-secret': 'RPC bí mật',
  'rpc-secret-tips': 'Xem RPC bí mật thủ công',
  'developer': 'Lập trình viên',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Mock User-Agent',
  'aria2-conf-path': 'Đường dẫn aria2.conf tích hợp sẵn',
  'app-log-path': 'Đường dẫn nhật ký ứng dụng',
  'download-session-path': 'Đường dẫn phiên tải về',
  'session-reset': 'Đặt lại phiên tải xuống',
  'factory-reset': 'Khôi phục cài đặt gốc',
  'factory-reset-confirm': 'Bạn có chắc chắn muốn khôi phục cài đặt gốc?',
  'lab-warning': '⚠️ Kích hoạt các tính năng trong phòng thí nghiệm có thể dẫn đến sự cố ứng dụng hoặc mất dữ liệu, hãy cân nhắc cho quyết định của mình!',
  'download-protocol': 'Các giao thức',
  'protocols-default-client': 'Đặt làm máy khách mặc định cho các giao thức sau',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Tiện ích mở rộng',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Được cung cấp bởi Cộng đồng, ',
  'baidu-exporter-help': 'Nhấn vào đây để sử dụng',
  'auto-update': 'Tự động cập nhật',
  'auto-check-update': 'Tự động kiểm tra cập nhật',
  'last-check-update-time': 'Kiểm tra cập nhật lần cuối',
  'not-saved': 'Tùy chọn chưa được lưu',
  'not-saved-confirm': 'Các tùy chọn đã sửa đổi sẽ bị mất, bạn có chắc chắn thoát không?'
}
