export default {
  'basic': 'พื้นฐาน',
  'advanced': 'ขั้นสูง',
  'lab': 'ห้องทดลอง',
  'save': 'บันทึกและนำไปใช้',
  'save-success-message': 'บันทึกการตั้งค่าเรียบร้อยแล้ว',
  'save-fail-message': 'บันทึกการตั้งค่าไม่สำเร็จ',
  'discard': 'ทิ้ง',
  'startup': 'เริ่มขึ้น',
  'open-at-login': 'เปิดเมื่อเข้าสู่ระบบ',
  'keep-window-state': 'รักษาขนาดและตำแหน่งของหน้าต่างเมื่อออก',
  'auto-resume-all': 'ทำงานที่ยังไม่เสร็จทั้งหมดต่อโดยอัตโนมัติ',
  'default-dir': 'เส้นทางเริ่มต้น',
  'mas-default-dir-tips': 'เนื่องจากข้อจำกัดการอนุญาตแซนด์บ็อกซ์ของ App Store แนะนำให้ตั้งค่าไดเร็กทอรีดาวน์โหลดเริ่มต้นเป็น ~/Downloads',
  'transfer-settings': 'การส่งผ่าน',
  'transfer-speed-upload': 'จำกัดการอัพโหลด',
  'transfer-speed-download': 'จำกัดการดาวน์โหลด',
  'transfer-speed-unlimited': 'ไม่จำกัด',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'บันทึกลิงก์แม่เหล็กเป็นไฟล์ทอร์เรนต์',
  'bt-auto-download-content': 'ดาวน์โหลดเนื้อหาแม่เหล็กและทอร์เรนต์โดยอัตโนมัติ',
  'bt-force-encryption': 'บังคับการเข้ารหัส BT',
  'keep-seeding': 'เก็บ Seed ไว้จนกว่าจะหยุดเอง',
  'seed-ratio': 'เวลาแบ่งปัน BT',
  'seed-time': 'อัตราส่วนแบ่ง BT',
  'seed-time-unit': 'นาที',
  'task-manage': 'การจัดการงาน',
  'max-concurrent-downloads': 'งานที่ใช้งานสูงสุด',
  'max-connection-per-server': 'การเชื่อมต่อสูงสุดต่อเซิร์ฟเวอร์',
  'new-task-show-downloading': 'แสดงการดาวน์โหลดโดยอัตโนมัติหลังจากเพิ่มงาน',
  'no-confirm-before-delete-task': 'ไม่จำเป็นต้องมีการยืนยันก่อนที่จะลบงาน',
  'continue': 'ต่อ',
  'task-completed-notify': 'แจ้งเตือนหลังดาวน์โหลดเสร็จสิ้น',
  'auto-purge-record': 'ล้างบันทึกการดาวน์โหลดโดยอัตโนมัติเมื่อออกจากแอป',
  'ui': 'UI',
  'appearance': 'รูปร่าง',
  'theme-auto': 'อัตโนมัติ',
  'theme-light': 'สว่าง',
  'theme-dark': 'มืด',
  'auto-hide-window': 'ซ่อนหน้าต่างอัตโนมัติ',
  'run-mode': 'เรียกใช้ด้วย',
  'run-mode-standard': 'แอปพลิเคชั่นปกติ',
  'run-mode-tray': 'แถบแจ้งเตือนแอปพลิเคชัน',
  'run-mode-hide-tray': 'ซ่อนแอปพลิเคชันถาด',
  'tray-speedometer': 'ถาดแถบเมนูแสดงความเร็วแบบเรียลไทม์',
  'show-progress-bar': 'แสดงแถบความคืบหน้าการดาวน์โหลด',
  'language': 'ภาษา',
  'change-language': 'เปลี่ยนภาษา',
  'hide-app-menu': 'ซ่อนเมนูแอป (Windows & Linux เท่านั้น)',
  'proxy': 'พร็อกซี่',
  'enable-proxy': 'เปิดใช้งานพร็อกซี่',
  'proxy-bypass-input-tips': 'ข้ามการตั้งค่าพร็อกซีสำหรับโฮสต์และโดเมนเหล่านี้ หนึ่งรายการต่อบรรทัด',
  'proxy-scope-download': 'ดาวน์โหลด',
  'proxy-scope-update-app': 'อัปเดตแอปพลิเคชัน',
  'proxy-scope-update-trackers': 'อัปเดตแทร็กเกอร์',
  'proxy-tips': 'ดูคู่มือพร็อกซี',
  'bt-tracker': 'เซิร์ฟเวอร์ติดตาม',
  'bt-tracker-input-tips': 'เซิร์ฟเวอร์ตัวติดตาม หนึ่งตัวต่อบรรทัด',
  'bt-tracker-tips': 'ขอแนะนำ: ',
  'sync-tracker-tips': 'ซิงค์',
  'auto-sync-tracker': 'อัพเดทรายการตัวติดตามทุกวันโดยอัตโนมัติ',
  'port': 'Listen Ports',
  'bt-port': 'BT Listen Port',
  'dht-port': 'DHT Listen Port',
  'security': 'ความปลอดภัย',
  'rpc': 'RPC',
  'rpc-listen-port': 'พอร์ตฟัง RPC',
  'rpc-secret': 'RPC Secret',
  'rpc-secret-tips': 'ดูคู่มือลับ RPC',
  'developer': 'นักพัฒนา',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'จำลอง User-Agent',
  'aria2-conf-path': 'เส้นทาง aria2.conf ที่ฝังอยู่',
  'app-log-path': 'เส้นทางบันทึกแอป',
  'download-session-path': 'เส้นทางดาวน์โหลดเซสชัน',
  'session-reset': 'รีเซ็ตเซสชั่นการดาวน์โหลด',
  'session-reset-confirm': 'คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตเซสชันการดาวน์โหลด',
  'factory-reset': 'ตั้งค่าจากโรงงาน',
  'factory-reset-confirm': 'คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนกลับเป็นการตั้งค่าจากโรงงาน',
  'lab-warning': '⚠️ การเปิดใช้งานฟีเจอร์แล็บอาจส่งผลให้แอปขัดข้องหรือข้อมูลสูญหาย ตัดสินใจยอมรับความเสี่ยงเอง!',
  'download-protocol': 'โปรโตคอล',
  'protocols-default-client': 'ตั้งเป็นไคลเอนต์เริ่มต้นสำหรับโปรโตคอลต่อไปนี้',
  'protocols-magnet': 'แม่เหล็ก [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'ส่วนขยาย',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'มอบให้โดยชุมชน, ',
  'baidu-exporter-help': 'คลิกที่นี่เพื่อใช้งาน',
  'auto-update': 'อัพเดทอัตโนมัติ',
  'auto-check-update': 'ตรวจสอบการอัพเดทอัตโนมัติ',
  'last-check-update-time': 'ตรวจสอบการอัพเดทครั้งล่าสุด'
}
