export default {
  'active': 'Baixando',
  'waiting': 'Aguardando',
  'stopped': 'Parou',
  'new-task': 'Nova Tarefa',
  'new-bt-task': 'Nova Tarefa BT',
  'open-file': 'Abra o arquivo Torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Uma url por linha (magnet links são aceitos)',
  'thunder-link-tips': 'Dica: Thunder links não podem ser baixados após decodificados',
  'new-task-uris-required': 'Por favor, insira pelo menos um URL de recurso válido',
  'new-task-torrent-required': 'Por favor, selecione um arquivo torrent',
  'file-name': 'Nome',
  'file-extension': 'Ext',
  'file-size': 'Tamanho',
  'file-completed-size': 'Baixado',
  'selected-files-sum': 'Selecionado: {{selectedFilesCount}} arquivos, total {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Selecione pelo menos um arquivo',
  'task-gid': 'GID',
  'task-name': 'Nome da Tarefa',
  'task-out': 'Renomear',
  'task-out-tips': 'Opcional',
  'task-split': 'Splits',
  'task-dir': 'Diretório',
  'pause-task': 'Pausar Tarefa',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Autorização',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Erro',
  'task-piece': 'Artigo',
  'task-piece-length': 'Tamanho da peça',
  'task-num-pieces': 'Peças',
  'task-bittorrent-info': 'Torrent Info',
  'task-info-hash': 'Cerquilha',
  'task-bittorrent-creation-date': 'Data de criação',
  'task-bittorrent-comment': 'Comente',
  'task-progress-info': 'Progresso',
  'task-status': 'Status',
  'task-num-seeders': 'Semeadores',
  'task-connections': 'Conexões',
  'task-file-size': 'Tamanho',
  'task-download-speed': 'Velocidade de download',
  'task-upload-speed': 'Velocidade de upload',
  'task-download-length': 'Baixado',
  'task-upload-length': 'Carregado',
  'task-ratio': 'Razão',
  'task-peer-host': 'Hospedeiro',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Cliente',
  'navigate-to-downloading': 'Navegar para o Downloading',
  'show-advanced-options': 'Opções Avançadas',
  'copyright-warning': 'Aviso de Copyright',
  'copyright-warning-message': 'O arquivo que você deseja baixa pode ser protegido por direitos de copyright de áudio ou vídeo, tenha certeza que você possui os direitos de licensa.',
  'copyright-yes': 'Sim, Eu Tenho',
  'copyright-no': 'Não',
  'copyright-error-message': 'A operação falhou devido os direitos de copyright',
  'pause-task-success': 'Tarefa "{{taskName}}" pausada com sucesso',
  'pause-task-fail': 'Falha ao pausar a tarefa "{{taskName}}"',
  'resume-task': 'Resumir Tarefa',
  'resume-task-success': 'Tarefa "{{taskName}}" resumida com sucesso',
  'resume-task-fail': 'Falha ao resumir a tarefa "{{taskName}}"',
  'delete-task': 'Apagar Tarefa',
  'delete-selected-tasks': 'Apagar as Tarefas Selecionadas',
  'delete-task-confirm': 'Você tem certeza de que deseja apagar a seguinte tarefa de download: "{{taskName}}"?',
  'batch-delete-task-confirm': 'Tem certeza de que deseja remover {{count}} tarefas de download em lote?',
  'delete-task-label': 'Apagar com os Arquivos',
  'delete-task-success': 'Tarefa "{{taskName}}" apagada com sucesso',
  'delete-task-fail': 'Falha ao apagar a tarefa "{{taskName}}"',
  'remove-task-file-fail': 'Falha ao tentar deletar o arquivo da tarefa, por favor, apague manualmente',
  'remove-task-config-file-fail': 'Falha ao tentar deletar o arquivo de configuração da tarefa, por favor, apague manualmente',
  'move-task-up': 'Mover Tarefa para Cima',
  'move-task-down': 'Mover Tarefa para Baixar',
  'pause-all-task': 'Pausar Todas as Tarefas',
  'pause-all-task-success': 'Todas as tarefas foram pausadas com sucesso',
  'pause-all-task-fail': 'Falha ao pausar todas as tarefas',
  'resume-all-task': 'Resumir todas as tarefas',
  'resume-all-task-success': 'Todas as tarefas foram resumidas com sucesso',
  'resume-all-task-fail': 'Falha ao resumir todas as tarefas',
  'select-all-task': 'Selecione todas as tarefas',
  'clear-recent-tasks': 'Limpar todas as tarefas',
  'purge-record': 'Expurgar o Registro de Tarefas',
  'purge-record-success': 'O registro de tarefas foi expurgado com sucesso',
  'purge-record-fail': 'Falha ao expurgar o registro de tarefas',
  'batch-delete-task-success': 'Exclua com êxito as tarefas em lote',
  'batch-delete-task-fail': 'Falha ao excluir tarefas no lote',
  'refresh-list': 'Atualizar a Lista de Tarefas',
  'no-task': 'Não há downloads no momento',
  'copy-link': 'Copiar Link',
  'copy-link-success': 'Link copiado com sucesso',
  'remove-record': 'Apagar Registro de Tarefas',
  'remove-record-confirm': 'Você tem certeza de que deseja remover o registro de download da tarefa "{{taskName}}"?',
  'remove-record-label': 'Deletar com arquivos',
  'remove-record-success': 'Registro da tarefa "{{taskName}}" apagado com sucesso',
  'remove-record-fail': 'Falha ao apagar o registro da tarefa "{{taskName}}"',
  'show-in-folder': 'Exibir Tarefa na Pasta',
  'file-not-exist': 'O arquivo não existe ou foi apagado',
  'file-path-error': 'Erro no caminho do arquivo',
  'opening-task-message': 'Abrindo "{{taskName}}" ...',
  'get-task-name': 'Obter o nome da tarefa...',
  'remaining-prefix': 'Restante',
  'select-torrent': 'Arraste o torrent aqui, ou clique para selecionar',
  'task-info-dialog-title': '{{title}} Detalhes',
  'download-start-message': 'Iniciar download {{taskName}}',
  'download-pause-message': 'Pausar download {{taskName}}',
  'download-stop-message': '{{taskName}} download parado',
  'download-error-message': '{{taskName}} ocorreu um erro no download',
  'download-complete-message': '{{taskName}} download completado',
  'download-complete-notify': 'Download Completado',
  'bt-download-complete-message': '{{taskName}} download completado, propagação ...',
  'bt-download-complete-notify': 'Download do BT concluído, Propagação ...',
  'bt-download-complete-tips': 'Dicas: você pode parar a tarefa para terminar a propagação',
  'bt-stopping-seeding-tip': 'Parando a propagação, levará algum tempo para desconectar, aguarde ...',
  'download-fail-message': '{{taskName}} falha no download',
  'download-fail-notify': 'Falha no Download'
}
