export default {
  'active': 'جاري التحميل',
  'waiting': 'جاري الانتظار',
  'stopped': 'المتوقفة',
  'new-task': 'اضافة تحميل جديد',
  'new-bt-task': 'اضافة تحميل تورنت جديد',
  'open-file': 'فتح ملف تورنت...',
  'uri-task': 'رابط التحميل',
  'torrent-task': 'تورنت',
  'uri-task-tips': 'عنوان تحميل واحد لكل سطر، (يدعم الروابط المغناطيسية)',
  'thunder-link-tips': 'نصيحة: قد لا تكون روابط الرعد قابلة للتحميل بعد فك التشفير',
  'new-task-uris-required': 'الرجاء إدخال رابط تحميل صالح واحد على الأقل',
  'new-task-torrent-required': 'الرجاء اختيار ملف تورنت',
  'file-name': 'اسم الملف',
  'file-extension': 'نوع الملف',
  'file-size': 'حجم الملف',
  'file-completed-size': 'تم التنزيل',
  'selected-files-sum': 'الملف المختار: {{selectedFilesCount}} ملف, الحجم الكلي {{selectedFilesTotalSize}}',
  'select-at-least-one': 'الرجاء تحديد ملف واحد على الأقل',
  'task-gid': 'GID',
  'task-name': 'اسم التحميل',
  'task-out': 'إعادة تسمية',
  'task-out-tips': 'اختياري',
  'task-split': 'تقسيم',
  'task-dir': 'حفظ إلى',
  'pause-task': 'إيقاف مؤقت',
  'task-ua': 'UA',
  'task-user-agent': 'وكيل المستخدم',
  'task-authorization': 'تفويض',
  'task-referer': 'المرجع',
  'task-cookie': 'الكوكيز',
  'task-proxy': 'الخادم الوسيط',
  'task-error-info': 'خطأ',
  'task-piece': 'قطعة',
  'task-piece-length': 'حجم القطعة',
  'task-num-pieces': 'قطع',
  'task-bittorrent-info': 'معلومات التورنت',
  'task-info-hash': 'تجزئة',
  'task-bittorrent-creation-date': 'تاريخ الإنشاء',
  'task-bittorrent-comment': 'تعليق',
  'task-progress-info': 'تقدم',
  'task-status': 'حالة',
  'task-num-seeders': 'بزار',
  'task-connections': 'روابط',
  'task-file-size': 'بحجم',
  'task-download-speed': 'سرعة التنزيل',
  'task-upload-speed': 'سرعة التحميل',
  'task-download-length': 'تم التنزيل',
  'task-upload-length': 'تم الرفع',
  'task-ratio': 'نسبة',
  'task-peer-host': 'مضيف',
  'task-peer-ip': 'IP',
  'task-peer-client': 'عميل',
  'navigate-to-downloading': 'الانتقال إلى التحميل',
  'show-advanced-options': 'الخيارات المتقدمة',
  'copyright-warning': 'تحذير حقوق الطبع والنشر',
  'copyright-warning-message': 'قد يكون الملف الذي تريد تحميله محميًا بحقوق الطبع والنشر بالصوت أو بالفيديو، يرجى التأكد من حصولك على إذن للوصول إليه.',
  'copyright-yes': 'نعم، أملك الإذن',
  'copyright-no': 'لا، لا أملك الإذن',
  'copyright-error-message': 'فشل في إضافة التحميل بسبب حقوق الطبع والنشر',
  'pause-task-success': 'تم بنجاح ايقاف التحميل "{{taskName}}"',
  'pause-task-fail': ' فشل في ايقاف التحميل "{{taskName}}"',
  'resume-task': 'إستئناف التحميل',
  'resume-task-success': 'تم بنجاح إستئناف التحميل "{{taskName}}"',
  'resume-task-fail': 'فشل في إستئناف التحميل "{{taskName}}"',
  'delete-task': 'حذف التحميل',
  'delete-selected-tasks': 'حذف التحميلات المحددة',
  'delete-task-confirm': 'هل أنت متأكد أنك تريد حذف تحميل "{{taskName}}" ؟',
  'batch-delete-task-confirm': 'هل انت متأكد أنك تريد حذف {{count}} التحميلات دفعة واحدة',
  'delete-task-label': 'حذف مع الملفات',
  'delete-task-success': 'تم بنجاح حذف التحميل "{{taskName}}"',
  'delete-task-fail': 'فشل في حذف التحميل "{{taskName}}"',
  'remove-task-file-fail': 'فشل في حذف ملف(ات) التحميل، الرجاء حذفها يدويًا',
  'remove-task-config-file-fail': 'فشل في حذف ملف تهيئة التحميل (config file)، يرجى حذفه يدويًا',
  'move-task-up': 'تحريك التحميل لأعلى',
  'move-task-down': 'تحريك التحميل لأسغل',
  'pause-all-task': 'إيقاف جميع التحميلات',
  'pause-all-task-success': 'تم إيقاف جميع التحميلات بنجاح',
  'pause-all-task-fail': 'فشل في إيقاف جميع التحميلات',
  'resume-all-task': 'إستئناف جميع التحميلات',
  'resume-all-task-success': 'تم إستئناف جميع التحميلات بنجاح',
  'resume-all-task-fail': 'فشل في إستئناف جميع التحميلات',
  'select-all-task': 'تحديد جميع التحميلات',
  'clear-recent-tasks': 'حذف التحميلات الحديثة',
  'purge-record': 'تطهير سجل التحميل',
  'purge-record-success': 'تم تطهير سجلات المهة بنجاح',
  'purge-record-fail': 'فشل في تطهير سجلات التحميل',
  'batch-delete-task-success': 'تم بنجاح حذف التحميلات دفعة واحدة',
  'batch-delete-task-fail': 'فشل في حذف التحميلات دفعة واحدة',
  'refresh-list': 'تحديث قائمة التحميلات',
  'no-task': 'لا توجد تحميلات حالية',
  'copy-link': 'نسخ الرابط',
  'copy-link-success': 'تم نسخ الرابط بنجاح',
  'remove-record': 'حذف سجل التحميل',
  'remove-record-confirm': 'هل أنت متأكد انك تريد حذف سجل التحميل الخاص بـ "{{taskName}}" ؟',
  'remove-record-label': 'حذف مع الملفات',
  'remove-record-success': 'تم بنجاح حذف سجل التحميل الخاص بـ "{{taskName}}"',
  'remove-record-fail': 'فشل في حذف سجل التحميل الخاص بـ "{{taskName}}"',
  'show-in-folder': 'عرض التحميل في المجلد',
  'file-not-exist': 'الملف المُستَهدَف غير موجود أو تم حذفه',
  'file-path-error': 'خطأ في مسار الملف',
  'opening-task-message': 'جاري فتح "{{taskName}}" ...',
  'get-task-name': 'جاري جلب إسم الملف...',
  'remaining-prefix': 'متبقى',
  'select-torrent': 'أفلت ملف التورنت هنا، أو اضغط تحديد',
  'task-info-dialog-title': '{{title}} تفاصيل',
  'download-start-message': 'بدأ تحميل {{taskName}}',
  'download-pause-message': 'وقف تحميل {{taskName}} مؤقتاً',
  'download-stop-message': 'وقف تحميل {{taskName}}',
  'download-error-message': 'حدث خطأ أثناء تحميل {{taskName}}',
  'download-complete-message': 'اكتمل تحميل {{taskName}}',
  'download-complete-notify': 'اكتمل التحميل',
  'bt-download-complete-message': 'اكتمل تحميل {{taskName}، وعملية البَذر',
  'bt-download-complete-notify': 'اكتمل تحميل التورنت، وعملية البَذر...',
  'bt-download-complete-tips': 'نصيحة: يمكنك إيقاف التحميل لإنهاء عملية البَذر',
  'bt-stopping-seeding-tip': 'جاري إيقاف عملية البَذر، سيستغرق قطع الاتصال بعض الوقت، الرجاء الانتظار...',
  'download-fail-message': 'فشل تحميل {{taskName}}',
  'download-fail-notify': 'فشل التحميل'
}
