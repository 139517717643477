export default {
  'basic': 'Основні',
  'advanced': 'Розширені',
  'lab': 'Лабораторія',
  'save': 'Зберігти та Застосувати',
  'save-success-message': 'Налаштування збережено успішно',
  'save-fail-message': 'Помилка при зберіганні налаштувань',
  'discard': 'Відмінити',
  'startup': 'Стартап',
  'open-at-login': 'Запускати програму разом з запуском операційної системи',
  'keep-window-state': 'Під час закриття додатка, зберігати розмір і положення вікна',
  'auto-resume-all': 'Автоматично поновлювати всі невиконанні завдання',
  'default-dir': 'Шлях за замовчуванням',
  'mas-default-dir-tips': 'Через обмеження в App Store, рекомендовано встановити шлях за замовчення ~/Downloads',
  'transfer-settings': 'Передача',
  'transfer-speed-upload': 'Ліміт вивантаження',
  'transfer-speed-download': 'Ліміт завантаження',
  'transfer-speed-unlimited': 'Безлімітно',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Зберегти магнітне посилання як торрент-файл',
  'bt-auto-download-content': 'Автоматично завантажуйте вміст магніту та торрент',
  'bt-force-encryption': 'Обов\'язкова криптографія BT',
  'keep-seeding': 'Продовжуйте висівати, поки не зупините його вручну',
  'seed-ratio': 'Співвідношення насіння',
  'seed-time': 'Час насіння',
  'seed-time-unit': 'хвилин',
  'task-manage': 'Менеджер завдань',
  'max-concurrent-downloads': 'Максимум активних завдань',
  'max-connection-per-server': 'Максимум з\'єднання на сервер',
  'new-task-show-downloading': 'Автоматично відображати завантаження після додавання завдання',
  'no-confirm-before-delete-task': 'Перед видаленням завдання не потрібно підтверджувати',
  'continue': 'Продовжити',
  'task-completed-notify': 'Повідомлення після завершення завантаження',
  'auto-purge-record': 'Автоматично чистити записи про завантаження перед закриттям додатка',
  'ui': 'UI',
  'appearance': 'Зовнішній вигляд',
  'theme-auto': 'Автоматично',
  'theme-light': 'Світлий',
  'theme-dark': 'Темний',
  'auto-hide-window': 'Автозахист вікон',
  'run-mode': 'Виконати як',
  'run-mode-standard': 'Стандартне застосування',
  'run-mode-tray': 'Додаток у лотку',
  'run-mode-hide-tray': 'Приховати програму лотка',
  'tray-speedometer': 'Лоток панелі меню показує швидкість у режимі реального часу',
  'show-progress-bar': 'Показати панель завантаження',
  'language': 'Мова',
  'change-language': 'Змінити мову',
  'hide-app-menu': 'Сховати меню додатка (Тільки для Windows та Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Застосувати Proxy',
  'proxy-bypass-input-tips': 'Обхід налаштувань проксі для цих хостів та доменів, по одному на рядок',
  'proxy-scope-download': 'Завантажити',
  'proxy-scope-update-app': 'Оновити додаток',
  'proxy-scope-update-trackers': 'Оновити трекери',
  'proxy-tips': 'Перегляньте посібник з проксі',
  'bt-tracker': 'Tracker Сервер',
  'bt-tracker-input-tips': 'Tracker сервера, один в рядок',
  'bt-tracker-tips': 'Рекомендовано: ',
  'sync-tracker-tips': 'Сінхронізуватись',
  'auto-sync-tracker': 'Щодня оновлюйте список трекерів автоматично',
  'port': 'Слухайте порти',
  'bt-port': 'Порт прослуховування BT',
  'dht-port': 'Порт прослуховування DHT',
  'security': 'Безпека',
  'rpc': 'RPC',
  'rpc-listen-port': 'Порт прослуховування RPC',
  'rpc-secret': 'RPC Secret',
  'rpc-secret-tips': 'Дивитись інструкцію RPC Secret',
  'developer': 'Розробник',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Макет User-Agent',
  'aria2-conf-path': 'Вбудований шлях до aria2.conf',
  'app-log-path': 'Шлях до журналу додатка',
  'download-session-path': 'Завантажити шлях сесії',
  'factory-reset': 'Налаштування за замовчуванням',
  'factory-reset-confirm': 'Ви впевненні, що бажаєте повернутись до налаштувань за замовчуванням?',
  'lab-warning': '⚠️ Увімкнення функцій лабораторії може призвести до збою програми або втрати даних, вирішити на власний ризик!',
  'download-protocol': 'Протоколи',
  'protocols-default-client': 'Встановіть як клієнта за замовчуванням для таких протоколів',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Розширення',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Надається спільнотою, ',
  'baidu-exporter-help': 'Натисніть тут для використання',
  'auto-update': 'Автоматичне оновлення',
  'auto-check-update': 'Автоматично перевіряти оновлення',
  'last-check-update-time': 'В останнє оновлення перевірялось',
  'not-saved': 'Налаштування не збережено',
  'not-saved-confirm': 'Змінені параметри буде втрачено. Ви впевнені, що залишите?'
}
