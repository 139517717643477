export default {
  'active': 'Загрузки',
  'waiting': 'Ожидание',
  'stopped': 'Остановлено',
  'new-task': 'Новое задание',
  'new-bt-task': 'Новое BT задание',
  'open-file': 'Открыть Torrent файл...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Один URL-задания в строку (поддержка magnet)',
  'thunder-link-tips': 'Совет: Ссылки типа Thunder могут не загружаться после декодированния',
  'new-task-uris-required': 'Введите хотя бы один действительный URL-адрес ресурса',
  'new-task-torrent-required': 'Пожалуйста, выберите torrent файл',
  'file-name': 'Имя файла',
  'file-extension': 'Тип файла',
  'file-size': 'Размер',
  'file-completed-size': 'Завершенный',
  'selected-files-sum': 'Выбрано: {{selectedFilesCount}} файлов, общий размер {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Пожалуйста, выберите хотя бы один файл',
  'task-gid': 'GID',
  'task-name': 'Имя загрузки',
  'task-out': 'Переименовать',
  'task-out-tips': 'Необязательный',
  'task-split': 'Разбить',
  'task-dir': 'Сохранить как',
  'pause-task': 'Приостановить задание',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Авторизация',
  'task-referer': 'Реферал',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Ошибка',
  'task-piece': 'Кусок',
  'task-piece-length': 'Размер куска',
  'task-num-pieces': 'Шт',
  'task-bittorrent-info': 'Информация о торрентах',
  'task-info-hash': 'Хеш',
  'task-bittorrent-creation-date': 'Дата создания',
  'task-bittorrent-comment': 'Комментарий',
  'task-progress-info': 'Прогресс',
  'task-status': 'Статус',
  'task-num-seeders': 'Сеялки',
  'task-connections': 'Подключения',
  'task-file-size': 'Размер',
  'task-download-speed': 'Скорость загрузки',
  'task-upload-speed': 'Скорость загрузки',
  'task-download-length': 'Скачано',
  'task-upload-length': 'Загружено',
  'task-ratio': 'Соотношение',
  'task-peer-host': 'Хозяин',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Клиент',
  'navigate-to-downloading': 'Перейти к загрузке',
  'show-advanced-options': 'Расширенные опции',
  'copyright-warning': 'Предупреждение об авторских правах',
  'copyright-warning-message': 'Файл, который вы пытаетесь загрузить, имеет запись об авторских правах на видео или аудио контент, пожалуйста проверьте, имеете ли вы права на загрузку этого файла.',
  'copyright-yes': 'Да, у меня есть права',
  'copyright-no': 'Нет, у меня нет прав',
  'copyright-error-message': 'Ошибка при добавлении задачи из-за проблем с авторскими правами',
  'pause-task-success': 'Успешно остановлено задание "{{taskName}}"',
  'pause-task-fail': 'Ошибка во время остановки задания "{{taskName}}"',
  'resume-task': 'Возобновить задание',
  'resume-task-success': 'Успешно возобновлено задание "{{taskName}}"',
  'resume-task-fail': 'Ошибка во время возобновления задания "{{taskName}}"',
  'delete-task': 'Удалить задание',
  'delete-selected-tasks': 'Удалить выбранные задания',
  'delete-task-confirm': 'Вы уверены, что хотите удалить задание "{{taskName}}"?',
  'batch-delete-task-confirm': 'Вы уверены, что хотите удалить {{count}} задач загрузки в пакетном режиме?',
  'delete-task-label': 'Удалить вместе с файлами',
  'delete-task-success': 'Успешно удалено задание "{{taskName}}"',
  'delete-task-fail': 'Ошибка во время удаления задания "{{taskName}}"',
  'remove-task-file-fail': 'Ошибка во время удаления файла(ов) задания. Пожалуйста, удалите его (их) самостоятельно',
  'remove-task-config-file-fail': 'Ошибка при удалении файла конфигурации задания. Пожалуйста, удалите его самостоятельно',
  'move-task-up': 'Переместить задание вверх',
  'move-task-down': 'Переместить задание вниз',
  'pause-all-task': 'Приостановить все задания',
  'pause-all-task-success': 'Успешно приостановлены все задания',
  'pause-all-task-fail': 'Ошибка во время остановки всех заданий',
  'resume-all-task': 'Возобновить все задания',
  'resume-all-task-success': 'Успешно возобновлены все задания',
  'resume-all-task-fail': 'Ошибка во время возобновления всех заданий',
  'select-all-task': 'Выберите все задачи',
  'clear-recent-tasks': 'Очистить последние задания',
  'purge-record': 'Очистить записи о заданиях',
  'purge-record-success': 'Успешно очищены записи о заданиях',
  'purge-record-fail': 'Ошибка при очистке записей о заданиях',
  'batch-delete-task-success': 'Успешно удалить задачи в пакетном режиме',
  'batch-delete-task-fail': 'Не удалось удалить задачи в пакетном режиме',
  'refresh-list': 'Обновить список заданий',
  'no-task': 'Нет текущих заданий',
  'copy-link': 'Копировать ссылку',
  'copy-link-success': 'Успешно скопированна ссылка',
  'remove-record': 'Удалить запись про задание',
  'remove-record-confirm': 'Вы уверены, что хотите удалить запись про задание "{{taskName}}"?',
  'remove-record-label': 'Удалить вместе с файлами',
  'remove-record-success': 'Успешно удалена запись про задание "{{taskName}}"',
  'remove-record-fail': 'Ошибка при удалении записи про задание "{{taskName}}"',
  'show-in-folder': 'Отобразить файлы заданий в папке',
  'file-not-exist': 'Запрошенный файл не существует или был удален',
  'file-path-error': 'Ошибка в пути к файлу',
  'opening-task-message': 'Открытие "{{taskName}}" ...',
  'get-task-name': 'Получить имя задания...',
  'remaining-prefix': 'Осталось',
  'select-torrent': 'Перетяните torrent файл сюда, или нажмите выбрать',
  'task-info-dialog-title': '{{title}} Детали',
  'download-start-message': 'Началась загрузка {{taskName}}',
  'download-pause-message': 'Приостановить загрузку {{taskName}}',
  'download-stop-message': 'Остановить загрузку {{taskName}}',
  'download-error-message': 'Ошибка во время загрузки {{taskName}}',
  'download-complete-message': 'Завершена загрузка {{taskName}}',
  'download-complete-notify': 'Загрузка завершена',
  'bt-download-complete-message': 'Завершена загрузка {{taskName}}, раздача',
  'bt-download-complete-notify': 'BT Загрузка завершена, раздача...',
  'bt-download-complete-tips': 'Совет: Вы можете остановить задачу, чтобы остановить раздачу',
  'bt-stopping-seeding-tip': 'Остановка посева, потребуется некоторое время, чтобы отключиться, пожалуйста, подождите...',
  'download-fail-message': 'Не удалось загрузить {{taskName}}',
  'download-fail-notify': 'Ошибка загрузки'
}
