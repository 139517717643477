export default {
  'active': 'In corso',
  'waiting': 'In Pausa',
  'stopped': 'Terminate',
  'new-task': 'Nuova attività',
  'new-bt-task': 'Nuova attività BT',
  'open-file': 'Apri un file Torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Un url per linea (supporta magnet)',
  'thunder-link-tips': 'Nota: I Thunder links potrebbero non essere più scaricabili dopo il decoding',
  'new-task-uris-required': 'Per favore, inserisci almeno un url risorsa valido',
  'new-task-torrent-required': 'Per favore, inserisci un file torrent',
  'file-name': 'Nome',
  'file-extension': 'Estensione',
  'file-size': 'Dimensione',
  'file-completed-size': 'Completato',
  'selected-files-sum': 'Selezionati: {{selectedFilesCount}} files, dimensione totale {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Seleziona almeno un file',
  'task-gid': 'GID',
  'task-name': 'Nome attività',
  'task-out': 'Rinomina',
  'task-out-tips': '(opzionale)',
  'task-split': 'Dividi',
  'task-dir': 'Posizione file',
  'pause-task': 'Metti in pausa attività',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Autorizzazione',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Errore',
  'task-piece': 'Pezzo',
  'task-piece-length': 'Dimensione del pezzo',
  'task-num-pieces': 'Pezzi',
  'task-bittorrent-info': 'Informazioni sul torrent',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Data di creazione',
  'task-bittorrent-comment': 'Commento',
  'task-progress-info': 'Progresso',
  'task-status': 'Stato',
  'task-num-seeders': 'Seminatrici',
  'task-connections': 'Connessioni',
  'task-file-size': 'Dimensione',
  'task-download-speed': 'Velocità di download',
  'task-upload-speed': 'Velocità di caricamento',
  'task-download-length': 'Scaricato',
  'task-upload-length': 'Caricato',
  'task-ratio': 'Rapporto',
  'task-peer-host': 'Ospite',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Cliente',
  'navigate-to-downloading': 'Naviga per scaricare',
  'show-advanced-options': 'Opzioni avanzate',
  'copyright-warning': 'Avviso sul Copyright',
  'copyright-warning-message': 'Il file che stai cercando di scaricare potrebbe esssere un audio o video soggetto a Copyright, per favore, assicurati che tu abbia il permesso per accedervi.',
  'copyright-yes': 'Si, ho il permesso',
  'copyright-no': 'No, non ho il permesso',
  'copyright-error-message': 'Impossibile scaricare il file a causa di un problema di Copyright',
  'pause-task-success': '"{{taskName}}" messo in pausa con successo',
  'pause-task-fail': 'Impossibile mettere in pausa "{{taskName}}"',
  'resume-task': 'Ricomincia Task',
  'resume-task-success': 'Attività "{{taskName}}" ricominciata con successo',
  'resume-task-fail': 'Impossibile ricominciare l\'attività: "{{taskName}}"',
  'delete-task': 'Elimina attività',
  'delete-selected-tasks': 'Elimina attività selezionate',
  'delete-task-confirm': 'Sei sicuro di voler rimuovere l\'attività "{{taskName}}"?',
  'batch-delete-task-confirm': 'Sei sicuro di voler rimuovere {{count}} attività in batch?',
  'delete-task-label': 'Elimina con i file',
  'delete-task-success': 'attività "{{taskName}}" eliminata con successo',
  'delete-task-fail': 'Impossibile eliminare l\'attività "{{taskName}}"',
  'remove-task-file-fail': 'Imossibile eliminare i file(s) delle attività, per favore, eliminali manualmente',
  'remove-task-config-file-fail': 'Impossibile eliminare i file di configutazine delle attività, per favore, eliminali manualmente',
  'move-task-up': 'Muovi l\'attività più in alto',
  'move-task-down': 'Muovi l\'attività più in basso',
  'pause-all-task': 'Metti in pausa tutte le attività',
  'pause-all-task-success': 'Tutte le attività messe in pausa con successo',
  'pause-all-task-fail': 'Impossibile mettere in pausa tutte le attività',
  'resume-all-task': 'Ricomincia tutte le attività',
  'resume-all-task-success': 'Tutte le attività ricominciate con successo',
  'resume-all-task-fail': 'Impossibile ricominciate tutte le attività',
  'select-all-task': 'Seleziona tutte le attività',
  'clear-recent-tasks': 'Elimina le attività recenti',
  'purge-record': 'Elimina la cronologia delle attività',
  'purge-record-success': 'Cronologia delle attività eliminata con successo',
  'purge-record-fail': 'Impossibile eliminare la cronologia delle attività',
  'batch-delete-task-success': 'Attività eliminate in batch con successo',
  'batch-delete-task-fail': 'Impossibile eliminare in batch le attività',
  'refresh-list': 'Aggiorna la lista delle attività',
  'no-task': 'Non ci sono attività',
  'copy-link': 'Copia link',
  'copy-link-success': 'Link copiato con successo',
  'remove-record': 'Rimuovi cronologia delle attività',
  'remove-record-confirm': 'Sei sicuro di voler eliminare la cronologia di download di "{{taskName}}"?',
  'remove-record-label': 'Elimina con i file',
  'remove-record-success': 'cronologia dell\'attività "{{taskName}}" eliminata con successo',
  'remove-record-fail': 'Imposibile eliminare la cronologia dell\'attività "{{taskName}}"',
  'show-in-folder': 'Mostra le attività nela cartella',
  'file-not-exist': 'File target non esistente o eliminato',
  'file-path-error': 'Errore path del file',
  'opening-task-message': 'Apertura attività "{{taskName}}" ...',
  'get-task-name': 'Ottengo il nome del file...',
  'remaining-prefix': 'Rimanente',
  'select-torrent': 'Trascina il file torrent qua o clicca per selezionarlo',
  'task-info-dialog-title': 'Dettagli {{title}}',
  'download-start-message': 'Iniziato il download di {{taskName}}',
  'download-pause-message': 'Download di {{taskName}} in pausa',
  'download-stop-message': 'Download di {{taskName}} stoppato',
  'download-error-message': 'Errore durante il download di {{taskName}}',
  'download-complete-message': 'Dowload di {{taskName}} completato',
  'download-complete-notify': 'Download completato',
  'bt-download-complete-message': 'Completed downloading {{taskName}}, seeding',
  'bt-download-complete-notify': 'Dowload BT completato, seeding...',
  'bt-download-complete-tips': 'Suggerimento: È possibile interrompere un\'attività per fermare il seeding',
  'bt-stopping-seeding-tip': 'Seeding fermato, ci vorrà un po\' di tempo per disconnettersi, per favore, aspetta...',
  'download-fail-message': 'Impossibile scaricare {{taskName}}',
  'download-fail-notify': 'Download Fallito'
}
