export default {
  'basic': '基础设置',
  'advanced': '进阶设置',
  'lab': '实验室',
  'save': '保存并应用',
  'save-success-message': '偏好设置保存成功',
  'save-fail-message': '偏好设置保存失败',
  'discard': '放弃',
  'startup': '启动',
  'open-at-login': '开机自动启动',
  'keep-window-state': '恢复上次退出时窗口的大小和位置',
  'auto-resume-all': '自动开始未完成的任务',
  'default-dir': '默认下载路径',
  'mas-default-dir-tips': '因 App Store 的沙箱权限限制，默认下载路径建议设置为您的「下载」目录',
  'transfer-settings': '传输设置',
  'transfer-speed-upload': '上传限速',
  'transfer-speed-download': '下载限速',
  'transfer-speed-unlimited': '不限速',
  'bt-settings': 'BT 设置',
  'bt-save-metadata': '保存磁力链接元数据为种子文件',
  'bt-auto-download-content': '自动开始下载磁力链接、种子的文件',
  'bt-force-encryption': 'BT强制加密',
  'keep-seeding': '持续做种，直到手动停止',
  'seed-ratio': '做种分享率',
  'seed-time': '做种时间',
  'seed-time-unit': '分钟',
  'task-manage': '任务管理',
  'max-concurrent-downloads': '同时下载的最大任务数',
  'max-connection-per-server': '每个服务器最大连接数',
  'new-task-show-downloading': '新建任务后自动跳转到下载页面',
  'no-confirm-before-delete-task': '删除任务前无需确认',
  'continue': '断点续传',
  'task-completed-notify': '下载完成后通知',
  'auto-purge-record': '当应用退出时自动清除下载记录',
  'ui': '界面',
  'appearance': '外观',
  'theme-auto': '自动',
  'theme-light': '浅色',
  'theme-dark': '深色',
  'auto-hide-window': '自动隐藏窗口',
  'run-mode': '运行为',
  'run-mode-standard': '标准应用',
  'run-mode-tray': '托盘应用',
  'run-mode-hide-tray': '隐藏托盘应用',
  'tray-speedometer': '托盘显示实时速度',
  'show-progress-bar': '显示下载进度条',
  'language': '语言',
  'change-language': '切换语言',
  'hide-app-menu': '隐藏菜单栏（仅支持 Windows 和 Linux）',
  'proxy': '代理',
  'enable-proxy': '使用代理服务器',
  'proxy-bypass-input-tips': '忽略这些主机与域的代理设置，一行一个',
  'proxy-scope-download': '下载',
  'proxy-scope-update-app': '更新应用程序',
  'proxy-scope-update-trackers': '更新 Tracker 列表',
  'proxy-tips': '查看代理配置说明',
  'bt-tracker': 'Tracker 服务器',
  'bt-tracker-input-tips': 'Tracker 服务器，一行一个',
  'bt-tracker-tips': '推荐使用：',
  'sync-tracker-tips': '从服务器同步',
  'auto-sync-tracker': '每天自动更新 Tracker 服务器列表',
  'port': '监听端口',
  'bt-port': 'BT 监听端口',
  'dht-port': 'DHT 监听端口',
  'security': '安全性',
  'rpc': 'RPC',
  'rpc-listen-port': 'RPC 监听端口',
  'rpc-secure': '启用 RPC 安全连接',
  'rpc-secret': 'RPC 授权密钥',
  'rpc-secret-tips': '查看说明文档',
  'developer': '开发者',
  'user-agent': 'User-Agent',
  'mock-user-agent': '模拟用户代理（UA）',
  'aria2-conf-path': '内置的 aria2.conf 路径',
  'app-log-path': '应用日志路径',
  'download-session-path': '下载会话路径',
  'session-reset': '重置下载会话记录',
  'session-reset-confirm': '你确定要重置下载会话记录吗?',
  'factory-reset': '恢复初始设置',
  'factory-reset-confirm': '你确定要恢复为初始设置吗?',
  'lab-warning': '⚠️启用实验特性可能造成应用崩溃或数据丢失，请自行决定！',
  'download-protocol': '下载协议',
  'protocols-default-client': '设置为以下协议的默认客户端',
  'protocols-magnet': '磁力链接 [ magnet:// ]',
  'protocols-thunder': '迅雷链接 [ thunder:// ]',
  'browser-extensions': '浏览器扩展',
  'baidu-exporter': '百度网盘助手',
  'browser-extensions-tips': '社区提供的浏览器扩展「不保证可用性」，',
  'baidu-exporter-help': '点此查看使用说明',
  'auto-update': '自动更新',
  'auto-check-update': '自动检查更新',
  'last-check-update-time': '上次检查更新时间',
  'follow-metalink': '自动开始下载磁力链接、种子内的文件',
  'follow-torrent': '种子下载完后自动下载种子内容',
  'not-saved': '设置未保存',
  'not-saved-confirm': '已修改的设置将会丢失，确定要离开吗?'
}
