import Icon from '@/components/Icons/Icon'

Icon.register({
  'task-stop': {
    'width': 24,
    'height': 24,
    'paths': [{
      'd': 'M22,1H2C1.447,1,1,1.447,1,2v20c0,0.553,0.447,1,1,1h20c0.553,0,1-0.447,1-1V2C23,1.447,22.553,1,22,1z'
    }]
  }
})
