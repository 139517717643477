import Icon from '@/components/Icons/Icon'

Icon.register({
  'preference-lab': {
    'width': 24,
    'height': 24,
    'polygons': [{
      'points': '9,11.675 5.855,16 18.145,16 15,11.675 15,5 17,5 17,0 7,0 7,5 9,5'
    }],
    'paths': [{
      'd': 'M19.6,18H4.4l-0.898,1.235c-0.668,0.917-0.763,2.115-0.248,3.126S4.793,24,5.928,24h12.145 c1.135,0,2.159-0.628,2.674-1.639s0.42-2.209-0.248-3.126L19.6,18z'
    }]
  }
})
