export default {
  'basic': '基本設定',
  'advanced': '詳細設定',
  'lab': '実験室',
  'save': '保存して適用',
  'save-success-message': '設定を保存します',
  'save-fail-message': '設定を保存できませんでした',
  'discard': '放棄',
  'startup': '起動',
  'open-at-login': '自動的に起動します',
  'auto-resume-all': '起動後自動的に未完了タスクを再開',
  'keep-window-state': 'ウィンドウのサイズと位置を元に戻します',
  'default-dir': '既定の保存先',
  'mas-default-dir-tips': 'App Store の Sandbox 制限のため，デフォルトディレクトリ設定は「Download」を推奨します。',
  'transfer-settings': '転送設定',
  'transfer-speed-upload': 'アップロード制限',
  'transfer-speed-download': 'ダウンロード制限',
  'transfer-speed-unlimited': '無制限',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'マグネットリンクをトレントファイルとして保存',
  'bt-auto-download-content': '自動的に磁石と急流のコンテンツをダウンロードします',
  'bt-force-encryption': 'BT 強制暗号化',
  'keep-seeding': '手動で停止するまでシードを続けます',
  'seed-ratio': 'シード比率',
  'seed-time': 'シードタイム',
  'seed-time-unit': '分',
  'task-manage': 'タスク管理',
  'max-concurrent-downloads': '最大同時タスク数',
  'max-connection-per-server': '最大サーバ接続数',
  'new-task-show-downloading': '新規タスクを作成後自動的にタスク画面に移る',
  'no-confirm-before-delete-task': 'タスクを削除する前に確認は必要ありません',
  'continue': '続ける',
  'task-completed-notify': 'タスク完了後に通知する',
  'auto-purge-record': 'アプリケーション終了後自動的にタスク履歴を削除',
  'ui': 'UI',
  'appearance': 'テーマ',
  'theme-auto': '自動',
  'theme-light': 'ライト',
  'theme-dark': 'ダーク',
  'auto-hide-window': '自動非表示ウィンドウ',
  'run-mode': '実行者',
  'run-mode-standard': '標準アプリケーション',
  'run-mode-tray': 'トレイアプリケーション',
  'run-mode-hide-tray': 'トレイアプリケーションを非表示にする',
  'tray-speedometer': 'メニューバートレイは、リアルタイムの速度を示します',
  'show-progress-bar': 'ダウンロード進行状況の表示',
  'language': '言語',
  'change-language': '言語を切り替え',
  'hide-app-menu': 'メニューバーを隠す（Windows と Linux のみサポート）',
  'proxy': 'プロキシ',
  'enable-proxy': 'プロキシを使う',
  'proxy-bypass-input-tips': 'これらのホストおよびドメインのプロキシ設定を1行に1つずつバイパスします',
  'proxy-scope-download': 'ダウンロード',
  'proxy-scope-update-app': 'アプリケーションの更新',
  'proxy-scope-update-trackers': 'トラッカーを更新する',
  'proxy-tips': 'プロキシマニュアルを表示',
  'bt-tracker': 'トラッカーサーバー',
  'bt-tracker-input-tips': 'トラッカーサーバ、一行に一つ',
  'bt-tracker-tips': 'お勧め：',
  'sync-tracker-tips': '同期する',
  'auto-sync-tracker': 'トラッカーリストを毎日自動的に更新します',
  'port': 'リスンポート',
  'bt-port': 'BT リスンポート',
  'dht-port': 'DHT リスンポート',
  'security': 'セキュリティ',
  'rpc': 'RPC',
  'rpc-listen-port': 'RPCリッスンポート',
  'rpc-secret': 'RPCシークレット',
  'rpc-secret-tips': 'RPCシークレットマニュアルの閲覧',
  'developer': '開発者',
  'user-agent': 'User-Agent',
  'mock-user-agent': '偽装ユーザーエージェント（UA）',
  'aria2-conf-path': '組み込みの aria2.conf パス',
  'app-log-path': 'ログディレクトリを適用',
  'download-session-path': 'セッションパスをダウンロード',
  'factory-reset': '初期設定に戻す',
  'factory-reset-confirm': '本当に初期設定に戻しますか?',
  'lab-warning': '⚠️ベータ機能をオンにするとアプリケーションの強制終了やデータが損失する可能性があります。自己責任でお願いします。',
  'download-protocol': 'プロトコル',
  'protocols-default-client': '以下のプロトコルのデフォルトクライアントとして設定',
  'protocols-magnet': '磁石 [ magnet:// ]',
  'protocols-thunder': 'サンダー [ thunder:// ]',
  'browser-extensions': 'ブラウザ拡張機能',
  'baidu-exporter': 'バイドゥオンラインストレージ拡張機能',
  'browser-extensions-tips': '他のユーザによって作成されたものです。動作は保証できません。',
  'baidu-exporter-help': 'ここをクリックし使用説明を見る',
  'auto-update': '自動更新',
  'auto-check-update': '更新を自動で確認する',
  'last-check-update-time': '前回更新確認時間',
  'not-saved': '設定が保存されていません',
  'not-saved-confirm': '変更された設定は失われます、よろしいですか？'
}
