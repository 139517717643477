export default {
  'active':'Активен',
  'waiting':'чакане',
  'stopped':'спряно',
  'new-task': 'нова задача',
  'new-bt-task': 'Нова BT задача',
  'open-file': 'отваряне на торент файл...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'една URL задача в низ (поддръжка на magnet)',
  'thunder-link-tips': 'съвет: връзките от типа Thunder може да не се зареждат след декодиране',
  'new-task-uris-required': 'въведете валиден URL адрес на ресурс',
  'new-task-torrent-required': 'моля, изберете торент файл',
  'file-name': 'Име на файл',
  'file-extension':'тип файл',
  'file-size': 'Размер',
  'file-completed-size': 'Изтеглено',
  'selected-files-sum': 'избрано: {{selectedFilesCount}} файлове, общ размер {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Моля, изберете поне един файл',
  'task-gid': 'GID',
  'task-name':'Име на изтегляне',
  'task-out': 'Преименуване',
  'task-out-tips':'незадължителен',
  'task-split': 'разделяне',
  'task-dir': 'Запазване в',
  'pause-task': 'пауза на задачата',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Упълномощаване',
  'task-referer': 'препращане',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Грешка',
  'task-piece': 'Парче',
  'task-piece-length': 'Размер на парче',
  'task-num-pieces': 'Парчета',
  'task-bittorrent-info': 'Информация за торента',
  'task-info-hash': 'Хеш',
  'task-bittorrent-creation-date': 'Дата на създаване',
  'task-bittorrent-comment': 'Коментирайте',
  'task-progress-info': 'Напредък',
  'task-status': 'Състояние',
  'task-num-seeders': 'Сеялки',
  'task-connections': 'Връзки',
  'task-file-size': 'Размер',
  'task-download-speed': 'Скорост на сваляне',
  'task-upload-speed': 'Скорост на качване',
  'task-download-length': 'Изтеглено',
  'task-upload-length': 'Качено',
  'task-ratio': 'Съотношение',
  'task-peer-host': 'Водещ',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Клиент',
  'navigate-to-downloading': 'напред към изтегляне',
  'show-advanced-options': 'Разширени опции',
  'copyright-warning':'предупреждение за авторски права',
  'copyright-warning-message': 'файлът, който се опитвате да изтеглите, има авторски права върху видео или аудио съдържание, моля, проверете дали имате права да изтеглите този файл.',
  'copyright-yes': 'Да, имам права',
  'copyright-no': 'Не, нямам права',
  'copyright-error-message': 'грешка при добавяне на задача поради проблеми с авторските права',
  'pause-task-success': 'успешно спряна задача" {{TaskName}}"',
  'pause-task-fail': 'грешка при спиране на задачата" {{taskName}}"',
  'resume-task': 'възобновяване на задачата',
  'resume-task-success': 'успешно възобновена задача" {{TaskName}}"',
  'resume-task-fail': 'грешка при възобновяване на задачата" {{taskName}}"',
  'delete-task': 'изтриване на задача',
  'delete-selected-tasks': 'изтриване на избраните задачи',
  'delete-task-confirm': 'сигурни ли Сте, че искате да изтриете задачата "{{taskName}}"?',
  'batch-delete-task-confirm': 'Сигурни ли сте, че искате да изтриете {{count}} задачи за зареждане в партиден режим?',
  'delete-task-label': 'изтриване заедно с файловете',
  'delete-task-success': 'успешно изтрита задача" {{TaskName}}"',
  'delete-task-fail': 'грешка при изтриване на задача" {{taskName}}"',
  'remove-task-file-fail': 'грешка при изтриване на файл (файлове) на задача, моля, изтрийте го (тях) сами',
  'remove-task-config-file-fail': 'грешка при изтриване на конфигурационния файл на заданието, моля, изтрийте го сами',
  'move-task-up': 'Преместване на задача нагоре',
  'move-task-down': 'Преместване на задача надолу',
  'pause-all-task': 'пауза на всички задачи',
  'pause-all-task-success': 'всички задачи са успешно прекратени',
  'pause-all-task-fail': 'грешка при спиране на всички задачи',
  'resume-all-task': 'възобновяване на всички задачи',
  'resume-all-task-success': 'успешно възобновени всички задачи',
  'resume-all-task-fail': 'грешка при възобновяване на всички задачи',
  'select-all-task': 'Изберете всички задачи',
  'clear-recent-tasks': 'Изчистване на последните задачи',
  'purge-record': 'Изчистване на записките за задачи',
  'purge-record-success': 'успешно изчистени записи на задачи',
  'purge-record-fail': 'грешка при изчистване на записите за задачи',
  'batch-delete-task-success': 'успешно изтриване на задачи в партиден режим',
  'batch-delete-task-fail': 'Неуспешно изтриване на задачи в партиден режим',
  'refresh-list': 'обновяване на списъка със задачи',
  'no-task': 'няма текущи задачи',
  'copy-link': 'копиране на връзка',
  'copy-link-success': 'успешно копирана връзка',
  'remove-record': 'изтриване на запис за задача',
  'remove-record-confirm': 'Сигурни ли сте, че искате да изтриете записа за задачата "{{taskName}}"?',
  'remove-record-label':'изтриване с файлове',
  'remove-record-success': 'успешно изтрит запис за задача" {{taskName}}"',
  'remove-record-fail': 'грешка при изтриване на запис за задача "{{taskName}}"',
  'show-in-folder': 'показване на файловете със задачи в папка',
  'file-not-exist': 'търсеният файл не съществува или е изтрит',
  'file-path-error': 'Грешка в пътя към файла',
  'opening-task-message': 'отваряне "{{TaskName}}" ...',
  'get-task-name': 'получаване на име на задача...',
  'remaining-prefix':'ляво',
  'select-torrent':'плъзнете торент файла тук, или натиснете Избери',
  'task-info-dialog-title':'{{Title}} детайли',
  'download-start-message': 'изтеглянето започна {{taskName}}',
  'download-pause-message': 'спиране на изтеглянето {{taskName}}',
  'download-stop-message': 'спиране на изтеглянето {{taskName}}',
  'download-error-message': 'грешка при изтегляне {{taskName}}',
  'download-complete-message': 'Завършено изтегляне {{taskName}}',
  'download-complete-notify':'изтеглянето Завършено',
  'BT-download-complete-message': 'завършено изтегляне {{TaskName}}, раздаване',
  'BT-download-complete-notify': 'BT изтеглянето приключи, раздаване...',
  'BT-download-complete-tips': 'съвет: можете да спрете задачата, за да спрете раздаването',
  'bt-stopping-seeding-tip': 'Спирането на засяването ще отнеме известно време, за да прекъснете връзката, моля изчакайте...',
  'download-fail-message': 'не може да бъде изтеглено {{taskName}}',
  'download-fail-notify': 'грешка при зареждане'
}
