import Icon from '@/components/Icons/Icon'

Icon.register({
  'menu-task': {
    'width': 24,
    'height': 24,
    'paths': [{
      'd': 'M14,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h13c0.6,0,1,0.4,1,1S14.6,13,14,13z'
    }, {
      'd': 'M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z'
    }, {
      'd': 'M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z'
    }]
  }
})
