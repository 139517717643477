import Icon from '@/components/Icons/Icon'

Icon.register({
  'purge': {
    'width': 24,
    'height': 24,
    'raw': `<line fill="none" stroke-miterlimit="10" x1="1" y1="9" x2="23" y2="9"/>
      <line fill="none" stroke-miterlimit="10" x1="1" y1="3" x2="23" y2="3"/>
      <line fill="none" stroke-miterlimit="10" x1="1" y1="15" x2="11" y2="15"/>
      <line fill="none" stroke-miterlimit="10" x1="1" y1="21" x2="11" y2="21"/>
      <line fill="none" stroke-miterlimit="10" x1="16" y1="15" x2="22" y2="21"/>
      <line fill="none" stroke-miterlimit="10" x1="22" y1="15" x2="16" y2="21"/>`,
    'g': {
      'stroke': 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-width': '2'
    }
  }
})
