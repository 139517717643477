export default {
  'active': 'Đang tải về',
  'waiting': 'Đang chờ',
  'stopped': 'Ngừng',
  'new-task': 'Tác vụ mới',
  'new-bt-task': 'Tác vụ BT mới',
  'open-file': 'Mở tệp Torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Mỗi URL tác vụ một dòng (hỗ trợ magnet)',
  'thunder-link-tips': 'Mẹo: Liên kết Thunder có thể không tải được sau khi giải mã',
  'new-task-uris-required': 'Vui lòng nhập ít nhất một url tài nguyên hợp lệ',
  'new-task-torrent-required': 'Vui lòng chọn tệp Torrent',
  'file-name': 'Tên',
  'file-extension': 'Loại',
  'file-size': 'Kích thước',
  'file-completed-size': 'Đã hoàn thành',
  'selected-files-sum': 'Đã chọn: {{selectedFilesCount}} tập tin, tổng kích thước {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Vui lòng chọn ít nhất một tệp',
  'task-gid': 'GID',
  'task-name': 'Tên tác vụ',
  'task-out': 'Đổi tên',
  'task-out-tips': 'Không bắt buộc',
  'task-split': 'Chia nhỏ',
  'task-dir': 'Lưu đến',
  'pause-task': 'Tạm dừng Tác vụ',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Authorization',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Lỗi',
  'task-piece': 'Mảnh',
  'task-piece-length': 'Kích thước mảnh',
  'task-num-pieces': 'Các mảnh',
  'task-bittorrent-info': 'Thông tin Torrent',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Ngày tạo',
  'task-bittorrent-comment': 'Bình luận',
  'task-progress-info': 'Tiến trình',
  'task-status': 'Trạng thái',
  'task-num-seeders': 'Seeders',
  'task-connections': 'Kết nối',
  'task-file-size': 'Kích thước',
  'task-download-speed': 'Tốc độ tải về',
  'task-upload-speed': 'Tốc độ tải lên',
  'task-download-length': 'Đã tải xuống',
  'task-upload-length': 'Đã tải lên',
  'task-ratio': 'Tỉ lệ',
  'task-peer-host': 'Tổ chức',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Clients',
  'navigate-to-downloading': 'Điều hướng tải xuống',
  'show-advanced-options': 'Lựa chọn Nâng cao',
  'copyright-warning': 'Cảnh báo Bản quyền',
  'copyright-warning-message': 'Tập tin bạn muốn tải xuống có thể là âm thanh hoặc video có bản quyền, vui lòng đảm bảo rằng bạn có quyền truy cập vào nó.',
  'copyright-yes': 'Đúng, tôi được cấp phép',
  'copyright-no': 'Không, tôi không được cấp phép',
  'copyright-error-message': 'Không thể thêm Tác vụ vì vấn đề bản quyền',
  'pause-task-success': 'Dừng tác vụ thành công "{{taskName}}"',
  'pause-task-fail': 'Dừng tác vụ thất bại "{{taskName}}"',
  'resume-task': 'Tiếp tục Tác vụ',
  'resume-task-success': 'Tác vụ được tải lại thành công "{{taskName}}"',
  'resume-task-fail': 'Tác vụ không thể tải lại "{{taskName}}"',
  'delete-task': 'Xóa tác vụ',
  'delete-selected-tasks': 'Xóa những tác vụ được chọn',
  'delete-task-confirm': 'Bạn có chắc chắn muốn xóa tác vụ tải xuống "{{taskName}}"?',
  'batch-delete-task-confirm': 'Bạn có chắc chắn muốn xóa {{Count}} tác vụ tải xuống không?',
  'delete-task-label': 'Xóa kèm tập tin',
  'delete-task-success': 'Xóa tác vụ thành công "{{taskName}}"',
  'delete-task-fail': 'Xóa tác vụ thất bại "{{taskName}}"',
  'remove-task-file-fail': 'Không thể xóa (các) tập tin trong tác vụ, vui lòng xóa chúng theo cách thủ công',
  'remove-task-config-file-fail': 'Không thể xóa tập tin cấu hình tác vụ, vui lòng xóa chúng theo cách thủ công',
  'move-task-up': 'Đưa tác vụ lên',
  'move-task-down': 'Đưa tác vụ xuống',
  'pause-all-task': 'Dừng Tất cả Tác vụ',
  'pause-all-task-success': 'Dừng tất cả tác vụ thành công',
  'pause-all-task-fail': 'Dừng tất cả tác vụ thất bại',
  'resume-all-task': 'Tải lại tất cả tác vụ',
  'resume-all-task-success': 'Tải lại tất cả tác vụ thành công',
  'resume-all-task-fail': 'Tải lại tất cả tác vụ thất bại',
  'select-all-task': 'Chọn tất cả tác vụ',
  'clear-recent-tasks': 'Xóa các tác vụ gần đây',
  'purge-record': 'Làm mới bản ghi tác vụ',
  'purge-record-success': 'Làm mới bản ghi tác vụ thành công',
  'purge-record-fail': 'Làm mới tản ghi tác vụ thất bại',
  'batch-delete-task-success': 'Xóa các tác vụ hàng loạt thành công',
  'batch-delete-task-fail': 'Xóa các tác vụ hàng loạt thất bại',
  'refresh-list': 'Làm mới danh sách tác vụ',
  'no-task': 'Hiện tại không có tác vụ',
  'copy-link': 'Sao chép đường dẫn',
  'copy-link-success': 'Sao chép đường dẫn thành công',
  'remove-record': 'Xóa bản ghi tác vụ',
  'remove-record-confirm': 'Bạn có chắc chắn muốn xóa bản ghi tải xuống cho "{{taskName}}" không?',
  'remove-record-label': 'Xóa kèm tập tin',
  'remove-record-success': 'Xoá thành công bản ghi tác vụ cho "{{taskName}}"',
  'remove-record-fail': 'Xoá thất bại bản ghi tác vụ cho "{{taskName}}"',
  'show-in-folder': 'Hiển thị tác vụ trong thư mục',
  'file-not-exist': 'Tập tin mục tiêu không tồn tại hoặc đã bị xóa',
  'file-path-error': 'Lỗi đường dẫn tệp',
  'opening-task-message': 'Đang mở "{{taskName}}" ...',
  'get-task-name': 'Lấy tên tác vụ....',
  'remaining-prefix': 'Còn lại',
  'select-torrent': 'Kéo thả tệp torrent vào đây hoặc nhấp để chọn',
  'task-detail-title': 'Thông tin tác vụ',
  'task-info-dialog-title': '{{title}} Chi tiết',
  'download-start-message': 'Bắt đầu tải xuống {{taskName}}',
  'download-pause-message': 'Tạm dừng tải xuống {{taskName}}',
  'download-stop-message': 'Đã dừng tải xuống {{taskName}}',
  'download-error-message': 'Xảy ra lỗi khi tải xuống {{taskName}}',
  'download-complete-message': 'Đã hoàn tất tải xuống {{taskName}}',
  'download-complete-notify': 'Tải xuống hoàn tất',
  'bt-download-complete-message': 'Đã hoàn tất tải xuống {{taskName}}, đang seed',
  'bt-download-complete-notify': 'BT đã hoàn tất tải xuống, đang seed...',
  'bt-download-complete-tips': 'Mẹo: Bạn có thể dừng một tác vụ để kết thúc việc seed',
  'bt-stopping-seeding-tip': 'Ngừng seed, sẽ mất một thời gian để ngắt kết nối, vui lòng đợi...',
  'download-fail-message': 'Không thể tải xuống {{taskName}}',
  'download-fail-notify': 'Tải xuống thất bại'
}
