export default {
  'active': 'Mengunduh',
  'waiting': 'Mengunggu',
  'stopped': 'Terhenti',
  'new-task': 'Tugas Baru',
  'new-bt-task': 'Tugas BT baru',
  'open-file': 'Buka Berkas Torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Satu tugas per baris (mendukung magnet)',
  'thunder-link-tips': 'Tip: Thunder tautan mungkin tidak dapat diunduh setelah decoding',
  'new-task-uris-required': 'Silakan masukkan setidaknya satu url yang valid',
  'new-task-torrent-required': 'Silahkan pilih berkas torrent',
  'file-name': 'Nama',
  'file-extension': 'Perpanjangan',
  'file-size': 'Ukuran',
  'file-completed-size': 'Ukuran domplet',
  'selected-files-sum': 'Terpilih: {{selectedFilesCount}} berkas, total ukuran {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Pilih setidaknya satu file',
  'task-gid': 'GID',
  'task-name': 'Nama Tugas',
  'task-out': 'Ubah Nama',
  'task-out-tips': 'Opsional',
  'task-split': 'Pecahan',
  'task-dir': 'Simpan Ke',
  'pause-task': 'Tunda Tugas',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Otorisasi',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Kesalahan',
  'task-piece': 'Bagian',
  'task-piece-length': 'Ukuran Potongan',
  'task-num-pieces': 'Potongan',
  'task-bittorrent-info': 'Info Torrent',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Tanggal Pembuatan',
  'task-bittorrent-comment': 'Komentar',
  'task-progress-info': 'Kemajuan',
  'task-status': 'Status',
  'task-num-seeders': 'Seeders',
  'task-connections': 'Koneksi',
  'task-file-size': 'Ukuran',
  'task-download-speed': 'Kecepatan Download',
  'task-upload-speed': 'Kecepatan unggah',
  'task-download-length': 'Telah diunduh',
  'task-upload-length': 'Diupload',
  'task-ratio': 'Perbandingan',
  'task-peer-host': 'Tuan rumah',
  'task-peer-ip': 'AKU P',
  'task-peer-client': 'Klien',
  'navigate-to-downloading': 'Beralih ke Unduhan',
  'show-advanced-options': 'Setting Lanjutan',
  'copyright-warning': 'Peringatan Hak Cipta',
  'copyright-warning-message': 'File yang ingin Anda unduh mungkin berupa audio atau video yang dilindungi hak cipta, pastikan Anda memiliki izin untuk mengaksesnya.',
  'copyright-yes': 'Ya, Saya punya izin',
  'copyright-no': 'Tidak, Saya tidak punya izin',
  'copyright-error-message': 'Gagal menambahkan tugas karena masalah hak cipta',
  'pause-task-success': 'Tugas berhasil ditunda "{{taskName}}"',
  'pause-task-fail': 'Gagal menunda tugas "{{taskName}}"',
  'resume-task': 'Lanjutkan tugas',
  'resume-task-success': 'Berhasil melanjutkan tugas "{{taskName}}"',
  'resume-task-fail': 'Gagal melanjutkan tugas "{{taskName}}"',
  'delete-task': 'Hapus tugas',
  'delete-selected-tasks': 'Hapus tugas terpilih',
  'delete-task-confirm': 'Anda yakin ingin menghapus tugas unduhan "{{taskName}}"?',
  'batch-delete-task-confirm': 'Anda yakin ingin menghapus {{count}} tugas unduhan (batch)?',
  'delete-task-label': 'Hapus dengan File',
  'delete-task-success': 'Tugas "{{taskName}}" berhasil dihapus',
  'delete-task-fail': 'Tugas "{{taskName}}" gagal dihapus',
  'remove-task-file-fail': 'Gagal menghapus berkas tugas, silahkan hapus secara manual',
  'remove-task-config-file-fail': 'Gagal menghapus pengaturan berkas tugas, silahkan hapus secara manual',
  'move-task-up': 'Pidahkan Tugas ke Atas',
  'move-task-down': 'Pidahkan Tugas ke Bawah',
  'pause-all-task': 'Tunda Semua Tugas',
  'pause-all-task-success': 'Berhasil menunda semua tugas',
  'pause-all-task-fail': 'Gagal menunda semua tugas',
  'resume-all-task': 'Lanjutkan Semua Tugas',
  'resume-all-task-success': 'Berhasil melanjutkan semua tugas',
  'resume-all-task-fail': 'Gagal melanjutkan semua tugas',
  'select-all-task': 'Pilih Semua Tugas',
  'clear-recent-tasks': 'Bersihkan tugas terakhir',
  'purge-record': 'Bersihkan Catatan Tugas',
  'purge-record-success': 'Berhasil membersihkan catatan tugas',
  'purge-record-fail': 'Gagal membersihkan catatan tugas',
  'batch-delete-task-success': 'Berhasil menghapus tugas (batch)',
  'batch-delete-task-fail': 'Gagal menghapus tugas (batch)',
  'refresh-list': 'Muat Ulang Daftar',
  'no-task': 'Tidak ada tugas',
  'copy-link': 'Salin Link',
  'copy-link-success': 'Berhasil menyalin link',
  'remove-record': 'Hapus Data Tugas',
  'remove-record-confirm': 'Anda yakin ingin menghapus data unduhan "{{taskName}}"?',
  'remove-record-label': 'Hapus dengan Berkas',
  'remove-record-success': 'Catatan tugas berhasil dihapus untuk "{{taskName}}"',
  'remove-record-fail': 'Gagal menghapus catatan tugas untuk "{{taskName}}"',
  'show-in-folder': 'Tampilkan Tugas Di Folder',
  'file-not-exist': 'Berkas target tidak ada atau telah dihapus',
  'file-path-error': 'Lokasi berkas error',
  'opening-task-message': 'Membuka "{{taskName}}" ...',
  'get-task-name': 'Mendapatkan nama tugas...',
  'remaining-prefix': 'Tersisa',
  'select-torrent': 'Seret berkas torrent ke sini, atau klik untuk memilih',
  'task-info-dialog-title': '{{title}} Detail',
  'download-start-message': 'Memulai mengunduh {{taskName}}',
  'download-pause-message': 'Menunda mengunduh {{taskName}}',
  'download-stop-message': 'Berhenti mengunduh {{taskName}}',
  'download-error-message': 'Terjadi kesalahan saat mengunduh {{taskName}}',
  'download-complete-message': 'Selesai mengunduh {{taskName}}',
  'download-complete-notify': 'Unduh Selesai',
  'bt-download-complete-message': 'Selesai mengunduh {{taskName}}, penyemaian',
  'bt-download-complete-notify': 'BT Unduh Selesai, penyemaian...',
  'bt-download-complete-tips': 'Tips: Anda dapat menghentikan tugas untuk mengakhiri penyemaian',
  'bt-stopping-seeding-tip': 'Menghentikan penyemaian, perlu beberapa saat untuk memutuskan, harap tunggu...',
  'download-fail-message': 'Gagal mengunduh {{taskName}}',
  'download-fail-notify': 'Unduhan Gagal'
}
