export default {
  'basic': '기본',
  'advanced': '고급',
  'lab': '실험실',
  'save': '저장 및 적용',
  'save-success-message': '설정을 성공적으로 저장했습니다',
  'save-fail-message': '설정을 저장하지 못했습니다',
  'discard': '취소',
  'startup': '시작',
  'open-at-login': '로그인 시 실행',
  'keep-window-state': '창 크기 및 위치 기억',
  'auto-resume-all': '완료되지 않은 모든 작업 자동 재개',
  'default-dir': '기본 폴더',
  'mas-default-dir-tips': 'App Store의 샌드박스 권한 제한으로 인해 기본 다운로드 폴더는 ~/Downloads로 설정하는 것이 좋습니다.',
  'transfer-settings': '전송',
  'transfer-speed-upload': '업로드 제한',
  'transfer-speed-download': '다운로드 제한',
  'transfer-speed-unlimited': '무제한',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': '마그넷 링크를 토렌트 파일로 저장',
  'bt-auto-download-content': '마그넷 및 토렌트 내용 자동 다운로드',
  'bt-force-encryption': 'BT 강제 암호화',
  'keep-seeding': '수동으로 멈출 때까지 계속 배포',
  'seed-ratio': '배포 비율',
  'seed-time': '배포 시간',
  'seed-time-unit': '분',
  'task-manage': '작업 관리',
  'max-concurrent-downloads': '최대 활성 작업',
  'max-connection-per-server': '서버 당 최대 연결 수',
  'new-task-show-downloading': '작업 추가 후 자동으로 다운로드 표시',
  'no-confirm-before-delete-task': '작업을 삭제하기 전에 확인하지 않기',
  'continue': '계속',
  'task-completed-notify': '다운로드 완료 후 알림',
  'auto-purge-record': '앱 종료 시 다운로드 기록 자동 삭제',
  'ui': 'UI',
  'appearance': '테마',
  'theme-auto': '자동',
  'theme-light': '밝게',
  'theme-dark': '어둡게',
  'auto-hide-window': '창 자동으로 숨기기',
  'run-mode': '실행 모드',
  'run-mode-standard': '표준 애플리케이션',
  'run-mode-tray': '트레이 애플리케이션',
  'run-mode-hide-tray': '트레이 응용 프로그램 숨기기',
  'tray-speedometer': '메뉴 막대 트레이에 실시간 속도가 표시됩니다',
  'show-progress-bar': '다운로드 진행률 막대기 보이기',
  'language': '언어',
  'change-language': '언어 변경',
  'hide-app-menu': '앱 메뉴 숨기기 (Windows 및 Linux)',
  'proxy': '프록시',
  'enable-proxy': '프록시 사용',
  'proxy-bypass-input-tips': '프록시 설정을 우회할 호스트 및 도메인 (한 줄에 하나)',
  'proxy-scope-download': '다운로드',
  'proxy-scope-update-app': '애플리케이션 업데이트',
  'proxy-scope-update-trackers': '추적기 업데이트',
  'proxy-tips': '프록시 매뉴얼 보기',
  'bt-tracker': '트래커 서버',
  'bt-tracker-input-tips': '트래커 서버 (한 줄에 하나)',
  'bt-tracker-tips': '권장: ',
  'sync-tracker-tips': '동기화',
  'auto-sync-tracker': '매일 자동으로 트래커 목록 업데이트',
  'port': '청취 포트',
  'bt-port': 'BT 청취 포트',
  'dht-port': 'DHT 청취 포트',
  'security': '보안',
  'rpc': 'RPC',
  'rpc-listen-port': 'RPC 청취 포트',
  'rpc-secret': 'RPC 비밀',
  'rpc-secret-tips': 'RPC 비밀 매뉴얼 보기',
  'developer': '개발자',
  'user-agent': 'User-Agent',
  'mock-user-agent': '모의 사용자 에이전트',
  'aria2-conf-path': '내장 된 aria2.conf 경로',
  'app-log-path': '앱 로그 경로',
  'download-session-path': '다운로드 세션 경로',
  'session-reset': '다운로드 세션 초기화',
  'session-reset-confirm': '다운로드 세션을 초기화하시겠습니까?',
  'factory-reset': '공장 초기화',
  'factory-reset-confirm': '초기 설정으로 되돌리시겠습니까?',
  'lab-warning': '⚠️ 실험실 기능을 사용 설정하면 앱 충돌 또는 데이터 손실이 발생할 수 있으므로 신중히 결정하십시오!',
  'download-protocol': '프로토콜',
  'protocols-default-client': '다음 프로토콜의 기본 클라이언트로 설정',
  'protocols-magnet': '마그넷 [ magnet:// ]',
  'protocols-thunder': '썬더 [ thunder:// ]',
  'browser-extensions': '확장 프로그램',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': '커뮤니티에서 제공하는, ',
  'baidu-exporter-help': '이곳을 클릭하여 사용법 확인',
  'auto-update': '자동 업데이트',
  'auto-check-update': '자동으로 업데이트 확인',
  'last-check-update-time': '마지막 업데이트 확인 시간',
  'not-saved': '설정이 저장되지 않았습니다',
  'not-saved-confirm': '수정된 설정이 손실됩니다. 나가시겠습니까?'
}
