export default {
  'task-list': 'Tâches',
  'add-task': 'Ajouter une tâche',
  'about': 'À Propos de Motrix',
  'preferences': 'Préférences...',
  'check-for-updates': 'Vérifier les mises à jour...',
  'check-updates-now': 'Vérifier maintenant',
  'checking-for-updates': 'Vérification des mises à jour ...',
  'check-for-updates-title': 'Vérifier les mises à jour',
  'update-available-message': 'Une nouvelle version de Motrix est disponible, mise à jour maintenant?',
  'update-not-available-message': 'Vous êtes à jour!',
  'update-downloaded-message': 'Prêt à installer...',
  'update-error-message': 'Erreur de mise à jour',
  'engine-damaged-message': 'Le moteur est endommagé, veuillez réinstaller : (',
  'engine-missing-message': 'Le moteur est manquant, veuillez réinstaller : (',
  'system-error-title': 'Erreur système',
  'system-error-message': 'L\'application n\'a pas pu démarrer: {{message}}',
  'hide': 'Cacher Motrix',
  'hide-others': 'Cacher les autres',
  'unhide': 'Tout montrer',
  'show': 'Montrer Motrix',
  'quit': 'Quitter Motrix',
  'under-development-message': 'Désolé, cette fonctionnalité est en cours de développement...',
  'yes': 'Oui',
  'no': 'Non',
  'save': 'Sauvegarder',
  'reset': 'Jeter',
  'cancel': 'Annuler',
  'submit': 'Envoyer',
  'gt1d': '> 1 jour',
  'hour': 'h',
  'minute': 'm',
  'second': 's'
}
