export default {
  'active': 'در حال بارگیری',
  'waiting': 'در انتظار',
  'stopped': 'متوقف شد',
  'new-task': 'وظیفهٔ جدید',
  'new-bt-task': 'وظیفهٔ بیت‌تورنت جدید',
  'open-file': 'بازکردن پروندهٔ تورنت...',
  'uri-task': 'آدرس اینترنتی',
  'torrent-task': 'تورنت',
  'uri-task-tips': 'یک آدرس در هر خط(از مگنت پشتیبانی می‌شود)',
  'thunder-link-tips': 'نکته: پیوند‌های تاندر ممکن هست بعد کدگشایی قابل بارگیری نباشند',
  'new-task-uris-required': 'لطفاً حداقل یک آدرس معتبر وارد کنید',
  'new-task-torrent-required': 'لطفاً یک پروندهٔ تورنت را انتخاب کنید',
  'file-name': 'نام',
  'file-extension': 'پسوند',
  'file-size': 'اندازه',
  'file-completed-size': 'تکمیل شده',
  'selected-files-sum': 'گزیده: {{selectedFilesCount}} پرونده، اندازه کل {{selectedFilesTotalSize}}',
  'select-at-least-one': 'لطفاً حداقل یک پرونده را انتخاب کنید',
  'task-gid': 'GID',
  'task-name': 'نام وظیفه',
  'task-out': 'تغییرنام',
  'task-out-tips': 'اختیاری',
  'task-split': 'برش‌ها',
  'task-dir': 'ذخیره در',
  'pause-task': 'مکث',
  'task-ua': 'UA',
  'task-user-agent': 'عامل کاربر',
  'task-authorization': 'مجوز',
  'task-referer': 'ارجاع دهنده',
  'task-cookie': 'کوکی',
  'task-proxy': 'پیشکار',
  'task-error-info': 'خطا',
  'task-piece': 'قطعه',
  'task-piece-length': 'اندازه قطعه',
  'task-num-pieces': 'قطعات',
  'task-bittorrent-info': 'اطلاعات تورنت',
  'task-info-hash': 'هش',
  'task-bittorrent-creation-date': 'تاریخ ایجاد',
  'task-bittorrent-comment': 'توضیح',
  'task-progress-info': 'پیشرفت',
  'task-status': 'وضعیت',
  'task-num-seeders': 'بذرپاش',
  'task-connections': 'اتصالات',
  'task-file-size': 'اندازه',
  'task-download-speed': 'سرعت بارگیری',
  'task-upload-speed': 'سرعت بارگذاری',
  'task-download-length': 'بارگیری شد',
  'task-upload-length': 'بارگذاری شد',
  'task-ratio': 'نسبت',
  'task-peer-host': 'میزبان',
  'task-peer-ip': 'آی‌پی',
  'task-peer-client': 'کارخواه',
  'navigate-to-downloading': 'برو به دانلودها',
  'show-advanced-options': 'گزینه‌های پیشرفته',
  'copyright-warning': 'هشدار حق تکثیر',
  'copyright-warning-message': 'پرونده‌ای که می‌خواهید بارگیری کنید ممکن است صوت یا ویدیوی تحت حمایت قانون حق تکثیر باشد، مطمئن شوید که اجازه دسترسی به آن را دارید.',
  'copyright-yes': 'بله، من اجازه دارم',
  'copyright-no': 'خیر، من اجازه ندارم',
  'copyright-error-message': 'به دلیل حق تکثیر، افزودن وظیفه انجام نشد',
  'pause-task-success': 'توقف وظیفه "{{taskName}}" با موفقیت انجام شد',
  'pause-task-fail': 'توقف وظیفه "{{taskName}}" شکست خورد',
  'resume-task': 'از سرگیری وظیفه',
  'resume-task-success': 'از سرگیری وظیفه "{{taskName}}" با موفقیت انجام شد',
  'resume-task-fail': 'از سرگیری وظیفه "{{taskName}}" شکست خورد',
  'delete-task': 'حذف وظیفه',
  'delete-selected-tasks': 'وظایف گزیده را حذف کن',
  'delete-task-confirm': 'آیا از حذف وظیفه "{{taskName}}" مطمئنید؟',
  'batch-delete-task-confirm': 'آیا از حذف {{count}} وظیفه به صورت دسته جمعی مطمئنید؟',
  'delete-task-label': 'حذف به همراه پرونده‌ها',
  'delete-task-success': 'حذف وظیفه "{{taskName}}" با موفقیت انجام شد',
  'delete-task-fail': 'حذف وظیفه "{{taskName}}" شکست خورد',
  'remove-task-file-fail': 'حذف وظیفه(ها) شکست خورد، لطفاً به صورت دستی حذفشان کنید',
  'remove-task-config-file-fail': 'حذف پروندهٔ پیکربندی وظیفه شکست خورد، لطفاً به صورت دستی حذفشان کنید',
  'move-task-up': 'جابجایی وظیفه به بالا',
  'move-task-down': 'جابجایی وظیفه به پایین',
  'pause-all-task': 'توقف همهٔ وظایف',
  'pause-all-task-success': 'توقف همهٔ وظایف با موفقیت انجام شد',
  'pause-all-task-fail': 'توقف همهٔ وظایف شکست خورد',
  'resume-all-task': 'از سرگیری همهٔ وظایف',
  'resume-all-task-success': 'از سرگیری همهٔ وظایف با موفقیت انجام شد',
  'resume-all-task-fail': 'از سرگیری همهٔ وظایف شکست خورد',
  'select-all-task': 'انتخاب همه وظایف',
  'clear-recent-tasks': 'پاک کردن وظایف اخیر',
  'purge-record': 'پاک کردن سابقهٔ وظایف',
  'purge-record-success': 'پاک کردن سابقهٔ وظایف با موفقیت انجام شد',
  'purge-record-fail': 'پاک کردن سابقهٔ وظایف شکست خورد',
  'batch-delete-task-success': 'حذف دسته جمعی وظایف با موفقیت انجام شد',
  'batch-delete-task-fail': 'حذف دسته جمعی وظایف شکست خورد',
  'refresh-list': 'تازه کردن لیست وظایف',
  'no-task': 'فعلا وظیفه‌ای وجود ندارد',
  'copy-link': 'رونوشت از پیوند',
  'copy-link-success': 'رونوشت از پیوند با موفقیت انجام شد',
  'remove-record': 'پاک کردن سابقهٔ وظیفه',
  'remove-record-confirm': 'آیا از حذف سابقه "{{taskName}}" مطمئنید؟',
  'remove-record-label': 'حذف به همراه پرونده‌ها',
  'remove-record-success': 'پاک کردن سابقهٔ وظیفه "{{taskName}}" با موفقیت انجام شد',
  'remove-record-fail': 'پاک کردن سابقهٔ وظیفه "{{taskName}}" شکست خورد',
  'show-in-folder': 'نمایش وظیفه در پوشه',
  'file-not-exist': 'پروندهٔ هدف وجود ندارد یا حذف شده است',
  'file-path-error': 'خطای مسیر پرونده',
  'opening-task-message': 'گشودن "{{taskName}}" ...',
  'get-task-name': 'گرفتن نام وظیفه...',
  'remaining-prefix': 'باقیمانده',
  'select-torrent': 'پروندهٔ تورنت را اینجا بکشید، یا برای گزینش کلیک کنید',
  'task-info-dialog-title': '{{title}} جزئیات',
    'download-start-message': 'بارگیری {{taskName}} شروع شد',
  'download-pause-message': 'مکث در بارگیری {{taskName}}',
  'download-stop-message': 'بارگیری {{taskName}} متوقف شد',
  'download-error-message': 'هنگام بارگیری {{taskName}} خطای رخ داد',
  'download-complete-message': 'بارگیری {{taskName}} تکمیل شد',
  'download-complete-notify': 'بارگیری تکمیل شد',
  'bt-download-complete-message': 'بارگیری {{taskName}} تکمیل شد، بذرپاشی',
  'bt-download-complete-notify': 'بارگیری بیت‌تورنت تکمیل شد، بذرپاشی...',
  'bt-download-complete-tips': 'نکات: شما می‌توانید با توقف یک وظیفه، به بذرپاشی آن را پایان دهید',
  'bt-stopping-seeding-tip': 'متوقف کردن بذرپاشی، قطع ارتباط مدتی طول خواهد کشید، لطفاً صبر کنید ...',
  'download-fail-message': 'بارگیری {{taskName}} شکست خورد',
  'download-fail-notify': 'بارگیری شکست خورد'
}
