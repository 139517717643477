export default {
  'task-list': 'Tasques',
  'add-task': 'Afegir tasca',
  'about': 'Sobre Motrix',
  'preferences': 'Preferències...',
  'check-for-updates': 'Comprovar actualitzacions...',
  'check-updates-now': 'Comprovar ara',
  'checking-for-updates': 'Comprovant actualitzacions...',
  'check-for-updates-title': 'Comprovar actualitzacions',
  'update-available-message': 'Hi ha una nova versió de Motrix. Actualitzar ara?',
  'update-not-available-message': 'Estàs en l\'última versió!',
  'update-downloaded-message': 'Llest per instal·lar...',
  'update-error-message': 'Error mentre s\'actualitzava',
  'engine-damaged-message': 'El motor està danyat, per favor reinstal·la :(',
  'engine-missing-message': 'No es troba el motor, por favor reinstal·la :(',
  'system-error-title': 'Error del sistema',
  'system-error-message': 'L\'aplicació va fallar en iniciar: {{message}}',
  'hide': 'Ocultar Motrix',
  'hide-others': 'Ocultar altres',
  'unhide': 'Mostrar tot',
  'show': 'Mostrar Motrix',
  'quit': 'Sortir de Motrix',
  'under-development-message': 'Ho sentim, aquesta característica està en desenvolupament...',
  'yes': 'Sí',
  'no': 'No',
  'save': 'Desa',
  'reset': 'Descarta',
  'cancel': 'Cancel·lar',
  'submit': 'Enviar',
  'gt1d': '> 1 dia',
  'hour': 'h',
  'minute': 'm',
  'second': 's'
}
