import Icon from '@/components/Icons/Icon'

Icon.register({
  'delete': {
    'width': 24,
    'height': 24,
    'raw': `<line fill="none" stroke-miterlimit="10" x1="19" y1="5" x2="5" y2="19" /><line fill="none" stroke-miterlimit="10" x1="19" y1="19" x2="5" y2="5" />`,
    'g': {
      'stroke': 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-width': '2'
    }
  }
})
