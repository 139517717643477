export default {
  'active': 'Descarregant',
  'waiting': 'Esperant',
  'stopped': 'Detinguda',
  'new-task': 'Nova Tasca',
  'new-bt-task': 'Nova Tasca BT',
  'open-file': 'Obrir arxiu Torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Una URL de tasca per línia (suporta magnet)',
  'thunder-link-tips': 'Tip: És possible que els enllaços Thunder no es puguin descarregar després de la descodificació.',
  'new-task-uris-required': 'Per favor, introdueix al menys una URL de recurs vàlida',
  'new-task-torrent-required': 'Seleccioni un arxiu torrent',
  'file-name': 'Nom',
  'file-extension': 'Extensió',
  'file-size': 'Mida',
  'file-completed-size': 'Descarregat',
  'selected-files-sum': 'Seleccionat: {{selectedFilesCount}} arxius, mida total: {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Seleccioneu com a mínim un fitxer',
  'task-gid': 'GID',
  'task-name': 'Nom de la tasca',
  'task-out': 'Canviar nom',
  'task-out-tips': 'Opcional',
  'task-split': 'Dividir',
  'task-dir': 'Guardar a',
  'pause-task': 'Pausar tasca',
  'task-ua': 'UA',
  'task-user-agent': 'Usuari-Agent',
  'task-authorization': 'Autorització',
  'task-referer': 'Referent',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Error',
  'task-piece': 'Peça',
  'task-piece-length': 'Mida de la peça',
  'task-num-pieces': 'Peces',
  'task-bittorrent-info': 'Informació del torrent',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Data de creació',
  'task-bittorrent-comment': 'Comentari',
  'task-progress-info': 'Progrés, progressar',
  'task-status': 'Estat',
  'task-num-seeders': 'Sembradores',
  'task-connections': 'Connexions',
  'task-file-size': 'Mida',
  'task-download-speed': 'Velocitat de descàrrega',
  'task-upload-speed': 'Velocitat de pujada',
  'task-download-length': 'Descarregat',
  'task-upload-length': 'Carregat',
  'task-ratio': 'Relació',
  'task-peer-host': 'Amfitrió',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Client',
  'navigate-to-downloading': 'Anar a Descàrregues',
  'show-advanced-options': 'Opcions avançades',
  'copyright-warning': 'Advertència sobre drets d\'autor',
  'copyright-warning-message': 'L\'arxiu que vols descarregar pot tenir drets d\'autor d\'audio o vídeo, per favor asegura\'t que tens permís per accedir a ell.',
  'copyright-yes': 'Sí, tinc permís',
  'copyright-no': 'No, no tinc permís.',
  'copyright-error-message': 'No s\'ha pogut afegir una tasca degut a un problema de drets d\'autor',
  'pause-task-success': 'S\'ha pausat la tasca "{{taskName}}"',
  'pause-task-fail': 'Hi ha hagut un problema al pausar la tasca "{{taskName}}"',
  'resume-task': 'Resumir tasca',
  'resume-task-success': 'S\'ha resumido la tasca "{{taskName}}"',
  'resume-task-fail': 'Hi ha hagut un error en resumir la tasca "{{taskName}}"',
  'delete-task': 'Eliminar tasca',
  'delete-selected-tasks': 'Eliminar tasques sel·leccionades',
  'delete-task-confirm': 'Estàs segur que vols eliminar la tasca "{{taskName}}"?',
  'batch-delete-task-confirm': 'Esteu segur que voleu suprimir {{count}} tasques de descàrrega en un lot?',
  'delete-task-label': 'Eliminar amb arxius',
  'delete-task-success': 'Tasca eliminada amb èxit "{{taskName}}"',
  'delete-task-fail': 'Hi ha hagut un error en eliminar la tasca "{{taskName}}"',
  'remove-task-file-fail': 'No s\'han eliminat els arxius de tasques, per favor, elimina\'ls manualment.',
  'remove-task-config-file-fail': 'No s\'ha pogut eliminar l\'arxiu de configuració de la tasca, per favor, elimina\' manualment.',
  'move-task-up': 'Desplaçar tasca cap a dalt',
  'move-task-down': 'Desplaçar tasca cap a baix',
  'pause-all-task': 'Pausar totes les tasques',
  'pause-all-task-success': 'S\'han pausat totes les tasques amb èxit',
  'pause-all-task-fail': 'Hi ha hagut un error en pausar totes les tasques',
  'resume-all-task': 'Resumir totes les tasques',
  'resume-all-task-success': 'S\'han resumit amb èxit totes les tasques',
  'resume-all-task-fail': 'Hi ha hagut un error en resumir totes les tasques',
  'select-all-task': 'Seleccioneu tota la tasca',
  'clear-recent-tasks': 'Limpiar les darreres tasques',
  'purge-record': 'Purgar registre de tasques',
  'purge-record-success': 'Registres de tasques purgats amb èxit',
  'purge-record-fail': 'No s\'ha pogut purgar els registres de tasques',
  'batch-delete-task-success': 'Suprimiu les tasques correctament al lot',
  'batch-delete-task-fail': 'No s\'ha pogut suprimir les tasques del lot',
  'refresh-list': 'Refrescar llista de tasques',
  'no-task': 'No hi ha tasques actuals',
  'copy-link': 'Copiar enllaç',
  'copy-link-success': 'Enllaç copiat amb èxit',
  'remove-record': 'Eliminar tasca',
  'remove-record-confirm': 'Estàs segur que vols eliminar la tasca "{{taskName}}"?',
  'remove-record-label': 'Eliminar amb arxius',
  'remove-record-success': 'S\'ha eliminat amb èxit la tasca "{{taskName}}"',
  'remove-record-fail': 'Hi ha hagut un error en eliminar la tasca "{{taskName}}"',
  'show-in-folder': 'Mostrar la carpeta de la tasca',
  'file-not-exist': 'L\'archivo objetiu no existeix o s\'ha eliminat',
  'file-path-error': 'Error en la ruta de l\'arxiu',
  'opening-task-message': 'Obrint "{{taskName}}"...',
  'get-task-name': 'Obtenint el nom de la tasca...',
  'remaining-prefix': 'restant',
  'select-torrent': 'Arrosega un arxiu Torrent aquí o fes click en seleccionar',
  'task-info-dialog-title': 'Detalls de {{title}}',
  'download-start-message': 'S\'ha iniciat la descàrrega de {{taskName}}',
  'download-pause-message': 'S\'ha pausat la descàrrega de {{taskName}}',
  'download-stop-message': 'S\'ha detingut la descàrrega de {{taskName}}',
  'download-error-message': 'Ha ocurrit un error en descarregar {{taskName}}',
  'download-complete-message': 'S\'ha terminat de descarregar {{taskName}}',
  'download-complete-notify': 'Descàrrega completada',
  'bt-download-complete-message': 'Descàrrega completada {{taskName}}. Compartint...',
  'bt-download-complete-notify': 'Descàrrega BT completa. Compartint...',
  'bt-download-complete-tips': 'Tips: Pot detenir una tasca per deixar de compartir',
  'bt-stopping-seeding-tip': 'Aturar la sembra, es necessitarà un temps per desconnectar-se, espereu...',
  'download-fail-message': 'No s\'ha pogut descarregar {{taskName}}',
  'download-fail-notify': 'Descàrrega fallida'
}
