export default {
  'active': 'Laster ned',
  'waiting': 'Venter',
  'stopped': 'Stoppet',
  'new-task': 'Ny oppgave',
  'new-bt-task': 'Ny BT-oppgave',
  'open-file': 'Åpne torrentfil...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Én oppgave-URL per linje (støtter magnet)',
  'thunder-link-tips': 'Tips: Tordenlinker kan ikke lastes ned etter dekoding',
  'new-task-uris-required': 'Angi minst én gyldig ressurs-URL',
  'new-task-torrent-required': 'Velg en torrentfil',
  'file-name': 'Navn',
  'file-extension': 'Filetternavn',
  'file-size': 'Størrelse',
  'file-completed-size': 'Fullført',
  'selected-files-sum': 'Valgt: {{selectedFilesCount}} filer, total størrelse {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Velg minst én fil',
  'task-gid': 'GID',
  'task-name': 'Oppgavenavn',
  'task-out': 'Gi nytt navn',
  'task-out-tips': 'Valgfri',
  'task-split': 'Deler',
  'task-dir': 'Lagre til',
  'pause-task': 'Pause oppgave',
  'task-ua': 'UA',
  'task-user-agent': 'Brukeragent',
  'task-authorization': 'Autorisasjon',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Fullmektig',
  'task-error-info': 'Feil',
  'task-piece': 'Stykke',
  'task-piece-length': 'Stykkestørrelse',
  'task-num-pieces': 'Stykker',
  'task-bittorrent-info': 'Torrentinformasjon',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Opprettelsesdato',
  'task-bittorrent-comment': 'Kommentar',
  'task-progress-info': 'Framgang',
  'task-status': 'Status',
  'task-num-seeders': 'Seeders',
  'task-connections': 'Tilkoblinger',
  'task-file-size': 'Størrelse',
  'task-download-speed': 'Nedlastningshastighet',
  'task-upload-speed': 'Opplastningshastighet',
  'task-download-length': 'Lastet ned',
  'task-upload-length': 'Lastet opp',
  'task-ratio': 'Forhold',
  'task-peer-host': 'Vert',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Klient',
  'navigate-to-downloading': 'Naviger til Nedlasting',
  'show-advanced-options': 'Avanserte instillinger',
  'copyright-warning': 'Copyright Advarsel',
  'copyright-warning-message': 'Filen du vil laste ned kan være lydbeskyttet eller beskyttet av copyright, vær sikker på at du har tillatelse til å få tilgang til den.',
  'copyright-yes': 'Ja, jeg har tillatelse',
  'copyright-no': 'Nei, jeg har ikke tillatelse',
  'copyright-error-message': 'Kunne ikke legge til oppgave på grunn av problem med opphavsrett',
  'pause-task-success': 'Oppgave "{{taskName}}" er midlertidig stoppet',
  'pause-task-fail': 'Kunne ikke pause oppgaven "{{taskName}}"',
  'resume-task': 'Fortsett oppgave',
  'resume-task-success': 'Oppgaven "{{taskName}}" ble gjenopptatt',
  'resume-task-fail': 'Kunne ikke gjenoppta oppgaven "{{taskName}}"',
  'delete-task': 'Slett oppgave',
  'delete-selected-tasks': 'Slett valgte oppgaver',
  'delete-task-confirm': 'Er du sikker på at du vil fjerne nedlastingsoppgaven "{{taskName}}"?',
  'batch-delete-task-confirm': 'Er du sikker på at du vil fjerne {{count}} nedlastingsoppgaver i batch?',
  'delete-task-label': 'Slett med filer',
  'delete-task-success': 'Oppgave "{{taskName}}" er slettet',
  'delete-task-fail': 'Kunne ikke slette oppgaven "{{taskName}}"',
  'remove-task-file-fail': 'Kunne ikke slette oppgavefil (er), vennligst slett dem manuelt',
  'remove-task-config-file-fail': 'Kunne ikke slette oppgavekonfigurasjonsfilen. Slett den manuelt',
  'move-task-up': 'Flytt oppgaven',
  'move-task-down': 'Flytt oppgave ned',
  'pause-all-task': 'Sett alle oppgaver på pause',
  'pause-all-task-success': 'Pause alle oppgaver',
  'pause-all-task-fail': 'Kunne ikke stoppe alle oppgaver midlertidig',
  'resume-all-task': 'Fortsett alle oppgaver',
  'resume-all-task-success': 'Gjenopptatt alle oppgaver',
  'resume-all-task-fail': 'Kunne ikke gjenoppta alle oppgaver',
  'select-all-task': 'Velg alle oppgaver',
  'clear-recent-tasks': 'Fjern siste oppgaver',
  'purge-record': 'Tøm oppgavelogg',
  'purge-record-success': 'Tømming av oppgavelogger var vellykket',
  'purge-record-fail': 'Kunne ikke tømme oppgavelogger',
  'batch-delete-task-success': 'Slett oppgaver i batch',
  'batch-delete-task-fail': 'Kunne ikke slette oppgaver i batch',
  'refresh-list': 'Oppdater oppgaveliste',
  'no-task': 'Det er ingen aktuelle oppgaver',
  'copy-link': 'Kopier link',
  'copy-link-success': 'Koblingen ble kopiert',
  'remove-record': 'Fjern oppgaveliste',
  'remove-record-confirm': 'Er du sikker på at du vil fjerne nedlastingsposten for "{{taskName}}"?',
  'remove-record-label': 'Slett med filer',
  'remove-record-success': 'Nedlastingsposten for "{{taskName}}" er fjernet',
  'remove-record-fail': 'Kunne ikke fjerne nedlastingsposten for "{{taskName}}"',
  'show-in-folder': 'Vis oppgave i mappe',
  'file-not-exist': 'Målfilen eksisterer ikke eller er slettet',
  'file-path-error': 'Filsti feil',
  'opening-task-message': 'Åpner "{{taskName}}"...',
  'get-task-name': 'Henter oppgavens navn...',
  'remaining-prefix': 'Gjenstående',
  'select-torrent': 'Dra torrentfil hit, eller klikk for å velge',
  'task-detail-title': 'Oppgavedetaljer',
  'task-info-dialog-title': '{{title}} Detaljer',
  'download-start-message': 'Lastet ned {{taskName}}',
  'download-pause-message': 'Nedlasting av {{taskName}} er midlertidig stoppet',
  'download-stop-message': 'Nedlastingen stoppet ikke {{taskName}}',
  'download-error-message': 'Det oppstod en feil under nedlasting av {{taskName}}',
  'download-complete-message': 'Nedlastingen er fullført {{taskName}}',
  'download-complete-notify': 'Last ned fullført',
  'bt-download-complete-message': 'Nedlastingen er fullført, {{taskName}}, seeding',
  'bt-download-complete-notify': 'BT-nedlasting fullført, seeding...',
  'bt-download-complete-tips': 'Tips: Du kan stoppe en oppgave for å avslutte seedingen',
  'bt-stopping-seeding-tip': 'Når du stopper seedingen, vil det ta litt tid å koble fra, vent...',
  'download-fail-message': 'Kunne ikke laste ned {{taskName}}',
  'download-fail-notify': 'Nedlasting feilet'
}
