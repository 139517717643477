export default {
  'task-list': 'Görevler',
  'add-task': 'Görev Ekle',
  'about': 'Motrix Hakkında',
  'preferences': 'Ayarlar...',
  'check-for-updates': 'Güncellemeleri kontrol et...',
  'check-updates-now': 'Şimdi kontrol et',
  'checking-for-updates': 'Güncellemeleri kontrol ediyor ...',
  'check-for-updates-title': 'Güncellemeleri kontrol et',
  'update-available-message': 'Motrix\'in yeni bir sürümü var, şimdi güncelle?',
  'update-not-available-message': 'Gündemdesin!',
  'update-downloaded-message': 'Yüklemeye hazır...',
  'update-error-message': 'Güncelleme Hatası',
  'engine-damaged-message': 'Motor hasarlı, lütfen tekrar takın : (',
  'engine-missing-message': 'Motor eksik, lütfen tekrar takın : (',
  'system-error-title': 'Sistem hatası',
  'system-error-message': 'Uygulama başlatılamadı: {{message}}',
  'hide': 'Motrix\'i gizle',
  'hide-others': 'Diğerlerini gizle',
  'unhide': 'Hepsini Göster',
  'show': 'Motrix\'i Göster',
  'quit': 'Motrix\'ten çık',
  'under-development-message': 'Üzgünüz, bu özellik geliştirme aşamasında...',
  'yes': 'Evet',
  'no': 'Hayır',
  'save': 'Kayıt etmek',
  'reset': 'İptal Et',
  'cancel': 'İptal',
  'submit': 'Gönder',
  'gt1d': '> 1 gün',
  'hour': 'S',
  'minute': 'd',
  'second': 's'
}
