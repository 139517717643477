export default {
  'basic': 'Podstawowe',
  'advanced': 'Zaawansowane',
  'lab': 'Laboratorium',
  'save': 'Zapisz i zastosuj',
  'save-success-message': 'Pomyślnie zapisano ustawienia',
  'save-fail-message': 'Nie udało się zapisać ustawień',
  'discard': 'Odrzuć',
  'startup': 'Startup',
  'open-at-login': 'Uruchom przy logowaniu',
  'keep-window-state': 'Zachowaj wielkość i pozycję okna podczas wychodzenia',
  'auto-resume-all': 'Automatycznie uruchom wszystkie zatrzymane zadania',
  'default-dir': 'Domyślna ścieżka',
  'mas-default-dir-tips': 'Z powodu restrykcji dostępu spowodowanych trybem piaskownicy w App Store, domyślną rekomendawaną lokalizacją pobrań jest ~/Downloads',
  'transfer-settings': 'Przesyłanie',
  'transfer-speed-upload': 'Limit prędkości przesyłania',
  'transfer-speed-download': 'Limit prędkości pobierania',
  'transfer-speed-unlimited': 'Nieograniczona',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Zapisz link magnetyczny jako plik torrent',
  'bt-auto-download-content': 'Automatycznie pobieraj zawartość magnesu i torrenta',
  'bt-force-encryption': 'Obrigar a criptografia forçada do BT',
  'keep-seeding': 'Kontynuuj wysiew aż do zatrzymania go ręcznie',
  'seed-ratio': 'Stosunek nasion',
  'seed-time': 'Czas siewu',
  'seed-time-unit': 'minuty',
  'task-manage': 'Zarządzania zadaniami',
  'max-concurrent-downloads': 'Maksymalna liczba aktywnych zadań',
  'max-connection-per-server': 'Maksymalna liczba połączeń na serwer',
  'new-task-show-downloading': 'Automatycznie pokaż pobieranie po dodaniu nowego zadania',
  'no-confirm-before-delete-task': 'Usunięcie zadania nie będzie wymagać potwierdzenia',
  'continue': 'Kontynuuj',
  'task-completed-notify': 'Powiadomnie po ukończeniu zadania',
  'auto-purge-record': 'Automatycznie usuń pobrane zadania po zamknięciu aplikacji',
  'ui': 'UI',
  'appearance': 'Wygląd',
  'theme-auto': 'Auto',
  'theme-light': 'Jasny',
  'theme-dark': 'Ciemny',
  'auto-hide-window': 'Automatycznie chowaj okno',
  'run-mode': 'Uruchom jako',
  'run-mode-standard': 'Zwykła aplikacja',
  'run-mode-tray': 'Aplikacja z paska powiadomień',
  'run-mode-hide-tray': 'Ukryj aplikację zasobnika',
  'tray-speedometer': 'Pasek zadań będzie pokazywać aktualną prędkość',
  'show-progress-bar': 'Pokaż pasek postępu pobierania',
  'language': 'Język',
  'change-language': 'Zmień język',
  'hide-app-menu': 'Schowaj menu aplikacji (Tylko Windows i Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Włącz Proxy',
  'proxy-bypass-input-tips': 'Omiń ustawiania proxy dla tych hostów i domen, jeden na linię',
  'proxy-scope-download': 'Pobierz',
  'proxy-scope-update-app': 'Aktualizacja aplikacji',
  'proxy-scope-update-trackers': 'Aktualizacja trackera',
  'proxy-tips': 'Pokaż instrukcję proxy',
  'bt-tracker': 'Trackery',
  'bt-tracker-input-tips': 'Trackery, jeden na linię',
  'bt-tracker-tips': 'Zalecane: ',
  'sync-tracker-tips': 'Synchronizowane',
  'auto-sync-tracker': 'Codziennie aktualizuj listę trackerów',
  'port': 'Nasłuchujące porty',
  'bt-port': 'Torrent nasłuchujący port',
  'dht-port': 'DHT nasłuchujący port',
  'security': 'Bezpieczeństwo',
  'rpc': 'RPC',
  'rpc-listen-port': 'Port nasłuchu RPC',
  'rpc-secret': 'Sekret RPC',
  'rpc-secret-tips': 'Pokaż instrukcję sekretu RPC',
  'developer': 'Developer',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Udawaj user-agent\'a',
  'aria2-conf-path': 'Wbudowana ścieżka aria2.conf',
  'app-log-path': 'Ścieżka logów',
  'download-session-path': 'Ścieżka sesji pobranych',
  'factory-reset': 'Przywróć domyślne ustawiania',
  'factory-reset-confirm': 'Jesteś pewnien że chcesz przywrócić domyślne ustawienia',
  'lab-warning': '⚠️ Włączenie laboratorium może doprowadzić do błędów i utraty danych, włącz na własne ryzyko!',
  'download-protocol': 'Protokoły',
  'protocols-default-client': 'Ustaw jako domyślny klient dla tych protokołów',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Dodatki',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Dostarczone przez społeczność, ',
  'baidu-exporter-help': 'Naciśnij tutaj po pomoc',
  'auto-update': 'Aktualizuj automatycznie',
  'auto-check-update': 'Automatycznie sprawdzaj aktualizacje',
  'last-check-update-time': 'Ostatni raz kiedy sprawdzono aktualizację',
  'not-saved': 'Preferencje nie zostały zapisane',
  'not-saved-confirm': 'Zmienione preferencje zostaną utracone, czy na pewno chcesz wyjść?'
}
