import Icon from '@/components/Icons/Icon'

Icon.register({
  'preference-advanced': {
    'width': 24,
    'height': 24,
    'polygons': [{
      'points': '10.853,9.439 6.707,5.293 8,4 4,0 0,4 4,8 5.293,6.707 9.189,10.603'
    }],
    'paths': [{
      'd': 'M18.94,13.94C18.631,13.976,18.318,14,18,14c-0.305,0-0.608-0.018-0.912-0.053l-3.641,4.499 l4.518,4.518c1.381,1.381,3.619,1.381,5,0v0c1.381-1.381,1.381-3.619,0-5L18.94,13.94z'
    }, {
      'd': 'M20.271,6.771l-3.042-3.042l3.208-3.208C19.692,0.189,18.869,0,18,0c-3.314,0-6,2.686-6,6 c0,0.594,0.089,1.166,0.25,1.708l-10.789,8.73c-0.891,0.787-1.423,1.919-1.459,3.106c-0.037,1.188,0.424,2.351,1.264,3.19 C2.082,23.551,3.167,24,4.321,24c1.239,0,2.421-0.532,3.241-1.461l8.73-10.789C16.834,11.911,17.406,12,18,12c3.314,0,6-2.686,6-6 c0-0.869-0.189-1.692-0.521-2.438L20.271,6.771z'
    }]
  }
})
