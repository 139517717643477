import Icon from '@/components/Icons/Icon'

Icon.register({
  'refresh': {
    'width': 24,
    'height': 24,
    'raw': `<path data-cap="butt" fill="none" stroke-miterlimit="10" d="M22,12c0,5.5-4.5,10-10,10 S2,17.5,2,12S6.5,2,12,2c3.9,0,7.3,2.2,8.9,5.5"/>
      <polyline fill="none" stroke-miterlimit="10" points="21.8,1.7 21,7.6 15,6.8 "/>`,
    'g': {
      'stroke': 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-width': '2'
    }
  }
})
