import Icon from '@/components/Icons/Icon'

Icon.register({
  'win-minimize': {
    'width': 12,
    'height': 12,
    'raw': `<line x1="1" y1="6" x2="11" y2="6" fill="none" stroke-linecap="round" stroke-linejoin="round"/>`,
    'g': {
      'stroke': 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      'stroke-width': '1'
    }
  }
})
