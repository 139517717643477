export default {
  'active': 'Pobieranie',
  'waiting': 'Wstrzymane',
  'stopped': 'Zatrzymane',
  'new-task': 'Nowe zadanie',
  'new-bt-task': 'Nowe zadanie Torrent',
  'open-file': 'Otwórz plik torrent...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'Jeden link na linię (wspiera magnet)',
  'thunder-link-tips': 'Porada: Linki Thunder mogą nie być możliwe do pobrania po zdekodowaniu',
  'new-task-uris-required': 'Proszę wprowadzić przynajmniej jeden poprawny URL',
  'new-task-torrent-required': 'Proszę wybrać plik torrent',
  'file-name': 'Nazwa',
  'file-extension': 'Rozbudowa',
  'file-size': 'Rozmiar',
  'file-completed-size': 'Pobrano',
  'selected-files-sum': 'Wybrano: {{selectedFilesCount}} plików, Wspólny rozmiar {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Wybierz co najmniej jeden plik',
  'task-gid': 'GID',
  'task-name': 'Nazwa zadania',
  'task-out': 'Zmień nazwę',
  'task-out-tips': 'Opcjonalne',
  'task-split': 'Części',
  'task-dir': 'Zapisz jako',
  'pause-task': 'Zapauzuj',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Autoryzacja',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Błąd',
  'task-piece': 'Kawałek',
  'task-piece-length': 'Rozmiar elementu',
  'task-num-pieces': 'Kawałki',
  'task-bittorrent-info': 'Informacje o torrentach',
  'task-info-hash': 'Haszysz',
  'task-bittorrent-creation-date': 'Data utworzenia',
  'task-bittorrent-comment': 'Komentarz',
  'task-progress-info': 'Postęp',
  'task-status': 'Status',
  'task-num-seeders': 'Siewniki',
  'task-connections': 'Znajomości',
  'task-file-size': 'Rozmiar',
  'task-download-speed': 'Prędkość pobierania',
  'task-upload-speed': 'Prędkość wysyłania',
  'task-download-length': 'Pobrano',
  'task-upload-length': 'Przesłane',
  'task-ratio': 'Stosunek',
  'task-peer-host': 'Gospodarz',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Klient',
  'navigate-to-downloading': 'Automatycznie uruchom pobieranie',
  'show-advanced-options': 'Opcje zaawansowane',
  'copyright-warning': 'Ostrzeżenie dt. praw autorskich',
  'copyright-warning-message': 'Plik który chcesz pobrać może być chroniony prawami autorskimi, proszę upewnij się że masz prawo go pobierać/używać',
  'copyright-yes': 'Tak, mam prawo',
  'copyright-no': 'Nie, nie mam prawa',
  'copyright-error-message': 'Nie można było dodać zadania z powodu praw autorskich',
  'pause-task-success': 'Pomyślnie zapauzowano "{{taskName}}"',
  'pause-task-fail': 'Nie udało się zapauzować "{{taskName}}"',
  'resume-task': 'Wznów zadanie',
  'resume-task-success': 'Pomyślnie wznowiono "{{taskName}}"',
  'resume-task-fail': 'Nie udało się wznowić "{{taskName}}"',
  'delete-task': 'Usuń zadanie',
  'delete-selected-tasks': 'Usuń wybrane zadania',
  'delete-task-confirm': 'Czy jesteś pewien że chcesz usunąć zadanie "{{taskName}}"?',
  'batch-delete-task-confirm': 'Czy jesteś pewnien że chcesz usunąć {{count}} zadań?',
  'delete-task-label': 'Usuń z plikami',
  'delete-task-success': 'Pomyślnie usunięto "{{taskName}}"',
  'delete-task-fail': 'Nie udało sie usunąć "{{taskName}}"',
  'remove-task-file-fail': 'Nie udało się usunąć plików pochodzących z zadań, proszę, usuń je manualnie.',
  'remove-task-config-file-fail': 'Nie udało się usunąć pliku konfiguracyjnego zadanie, proszę, usuń je manualnie',
  'move-task-up': 'Przenieś zadanie do góry',
  'move-task-down': 'Przenieś zadanie do dołu',
  'pause-all-task': 'Wstrzymaj wszystkie zadania',
  'pause-all-task-success': 'Pomyślnie wstrzymano wszystkie zadania',
  'pause-all-task-fail': 'Nie udało się wstrzymać wszystkich zadań',
  'resume-all-task': 'Wznów wszystkie zadania',
  'resume-all-task-success': 'Pomyślnie wznowiono wszystkie zadania',
  'resume-all-task-fail': 'Nie udało się wznowić wszystkich zadań',
  'select-all-task': 'Wybierz wszystkie zadania',
  'clear-recent-tasks': 'Wyczyść ostatnie zadania',
  'purge-record': 'Usuń wszystkie rekordy zadań',
  'purge-record-success': 'Pomyślnie usunięto wszystkie rekordy zadań',
  'purge-record-fail': 'Nie udało się usunąć wszystkich rekordów zadań',
  'batch-delete-task-success': 'Pomyślnie usunięto wiele zadań',
  'batch-delete-task-fail': 'Nie udało się usunąć wielu zadań',
  'refresh-list': 'Odśwież listę zadań',
  'no-task': 'Nie ma obecnie żadnych zadań',
  'copy-link': 'Skopiuj link',
  'copy-link-success': 'Pomyślnie skopiowano link',
  'remove-record': 'Usuń rekord zadania',
  'remove-record-confirm': 'Czy jesteś pewien że chcesz usunąć rekord dla zadania "{{taskName}}"?',
  'remove-record-label': 'Usuń z plikami',
  'remove-record-success': 'Pomyślnie usunięto rekord dla zadania "{{taskName}}"',
  'remove-record-fail': 'Nie udało się usunąć rekordu dla zadania "{{taskName}}"',
  'show-in-folder': 'Pokaż zadania w folderze',
  'file-not-exist': 'Docelowe pliki nie istnieją lub zostały usunięte',
  'file-path-error': 'Błąd ścieżki pliku',
  'opening-task-message': 'Otiweranie "{{taskName}}" ...',
  'get-task-name': 'Pobieranie nazwy pliku...',
  'remaining-prefix': 'Pozostało',
  'select-torrent': 'Przenieś tutaj plik torrent, lub naciśnij aby wybrać',
  'task-info-dialog-title': 'Szczegóły {{title}}',
  'download-start-message': 'Rozpoczęto pobieranie {{taskName}}',
  'download-pause-message': 'Wstrzymano pobieranie {{taskName}}',
  'download-stop-message': 'Zatrzymano pobieranie {{taskName}}',
  'download-error-message': 'Wystąpił błąd podczas pobierania {{taskName}}',
  'download-complete-message': 'Ukończono pobieranie {{taskName}}',
  'download-complete-notify': 'Pobieranie ukończone',
  'bt-download-complete-message': 'Ukończono pobieranie {{taskName}}, seedowanie',
  'bt-download-complete-notify': 'Ukończono pobieranie pliku torrent, seedowanie...',
  'bt-download-complete-tips': 'Wskazówka: Możesz zatrzymań zadanie aby wyłączyć seedowanie',
  'bt-stopping-seeding-tip': 'Zatrzymywane seedowania, zajmie chwilę rozłączanie się od klientów, proszę czekać...',
  'download-fail-message': 'Nie udało się pobrać {{taskName}}',
  'download-fail-notify': 'Nie udało się pobrać'
}
