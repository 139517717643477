import Icon from '@/components/Icons/Icon'

Icon.register({
  'task-pause': {
    'width': 24,
    'height': 24,
    'paths': [{
      'd': 'M9,1H3C2.447,1,2,1.447,2,2v20c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1V2C10,1.447,9.553,1,9,1z'
    }, {
      'd': 'M21,1h-6c-0.553,0-1,0.447-1,1v20c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1V2C22,1.447,21.553,1,21,1z'
    }]
  }
})
