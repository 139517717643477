export default {
  'basic': 'Básico',
  'advanced': 'Avanzado',
  'lab': 'Lab',
  'save': 'Guardar y Aplicar',
  'save-success-message': 'Preferencias guardadas exitosamente',
  'save-fail-message': 'Hubo un error al guardar tus preferencias',
  'discard': 'Descartar',
  'startup': 'Inicio',
  'open-at-login': 'Abrir al iniciar sesión',
  'keep-window-state': 'Mantener el tamaño y la posición de la ventana al salir',
  'auto-resume-all': 'Reanudar automáticamente todas las tareas sin finalizar',
  'default-dir': 'Ruta por defecto',
  'mas-default-dir-tips': 'Debido a las restricciones de la tienda de aplicaciones, la ruta por defecto se recomienda que sea ~/Downloads',
  'transfer-settings': 'Transferencia',
  'transfer-speed-upload': 'Límite de subida',
  'transfer-speed-download': 'Límite de bajada',
  'transfer-speed-unlimited': 'Ilimitado',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Guardar enlace magnet como archivo torrent',
  'bt-auto-download-content': 'Descargar automáticamente el contenido de magnet y torrent',
  'bt-force-encryption': 'Forzar encriptación BT',
  'keep-seeding': 'Siga sembrando hasta detenerlo manualmente',
  'seed-ratio': 'Proporción de semillas',
  'seed-time': 'Tiempo de semilla',
  'seed-time-unit': 'minutos',
  'task-manage': 'Gestión de tareas',
  'max-concurrent-downloads': 'Tareas máximas activas',
  'max-connection-per-server': 'Conexiones máximas por servidor',
  'new-task-show-downloading': 'Mostrar automáticamente la descarga después de añadir una tarea',
  'no-confirm-before-delete-task': 'No se requiere confirmación antes de eliminar la tarea',
  'continue': 'Continuar',
  'task-completed-notify': 'Notificar después de que la descarga se complete',
  'auto-purge-record': 'Eliminar automáticamente el registro de descargas al salir',
  'ui': 'UI',
  'appearance': 'Apariencia',
  'theme-auto': 'Auto',
  'theme-light': 'Claro',
  'theme-dark': 'Oscuro',
  'auto-hide-window': 'Ocultar automáticamente ventanas',
  'run-mode': 'Correr como',
  'run-mode-standard': 'Aplicación estándar',
  'run-mode-tray': 'Aplicación de bandeja',
  'run-mode-hide-tray': 'Ocultar aplicación de bandeja',
  'tray-speedometer': 'La bandeja de la barra de menú muestra la velocidad en tiempo real',
  'show-progress-bar': 'Mostrar la barra de progreso de descarga',
  'language': 'Idioma',
  'change-language': 'Cambiar Idioma',
  'hide-app-menu': 'Ocultar el menú (Solo Windows y Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Activar Proxy',
  'proxy-bypass-input-tips': 'Omitir la configuración del proxy para estos hosts y dominios, uno por línea',
  'proxy-scope-download': 'Descargar',
  'proxy-scope-update-app': 'Actualizar aplicación',
  'proxy-scope-update-trackers': 'Actualizar Trackers',
  'proxy-tips': 'Ver manual para Proxy',
  'bt-tracker': 'Servidores de rastreadores',
  'bt-tracker-input-tips': 'Servidores de rastreadores, uno por línea',
  'bt-tracker-tips': 'Recomendado: ',
  'sync-tracker-tips': 'Sincronizar',
  'auto-sync-tracker': 'Actualice la lista de rastreadores todos los días automáticamente',
  'port': 'Puertos de Escucha',
  'bt-port': 'Puerto de escucha BT',
  'dht-port': 'Puerto de escucha DHT',
  'security': 'Seguridad',
  'rpc': 'RPC',
  'rpc-listen-port': 'Puerto de Escucha RPC',
  'rpc-secret': 'Clave RPC',
  'rpc-secret-tips': 'Ver manual de la clave RPC',
  'developer': 'Desarrollador',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Falsear Agente de Usuario',
  'aria2-conf-path': 'Ruta incorporada de aria2.conf',
  'app-log-path': 'Ruta del registro',
  'download-session-path': 'Ruta de descarga de la sesión',
  'factory-reset': 'Restauración de fábrica',
  'factory-reset-confirm': '¿Estás seguro que quieres restaurar de fábrica?',
  'lab-warning': '¡Habilitar las característticas "Lab" puede resultar en errores y pérdida de datos!',
  'download-protocol': 'Protocolos',
  'protocols-default-client': 'Establecer como cliente por defecto de los siguientes protocolos',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Extensiones',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'Proporcionados por la comunidad, ',
  'baidu-exporter-help': 'Presiona aqui para ver el uso',
  'auto-update': 'Auto-actualizar',
  'auto-check-update': 'Revisar automáticamente por actualizaciones',
  'last-check-update-time': 'Última revisión de actualizaciones',
  'not-saved': 'Preferencias no guardadas',
  'not-saved-confirm': 'Las preferencias cambiadas se perderán, ¿está seguro de irse?'
}
