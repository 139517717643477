export default {
  'active': 'Downloading',
  'waiting': 'Waiting',
  'stopped': 'Stopped',
  'new-task': 'New Task',
  'new-bt-task': 'New BT Task',
  'open-file': 'Open Torrent File...',
  'uri-task': 'URL',
  'torrent-task': 'Torrent',
  'uri-task-tips': 'One task url per line (supports magnet)',
  'thunder-link-tips': 'Tip: Thunder links may not be downloadable after decoding',
  'new-task-uris-required': 'Please enter at least one valid resource url',
  'new-task-torrent-required': 'Please select a torrent file',
  'file-name': 'Name',
  'file-extension': 'Extension',
  'file-size': 'Size',
  'file-completed-size': 'Completed',
  'selected-files-sum': 'Selected: {{selectedFilesCount}} files, total size {{selectedFilesTotalSize}}',
  'select-at-least-one': 'Please select at least one file',
  'task-gid': 'GID',
  'task-name': 'Task Name',
  'task-out': 'Rename',
  'task-out-tips': 'Optional',
  'task-split': 'Splits',
  'task-dir': 'Save to',
  'pause-task': 'Pause Task',
  'task-ua': 'UA',
  'task-user-agent': 'User-Agent',
  'task-authorization': 'Authorization',
  'task-referer': 'Referer',
  'task-cookie': 'Cookie',
  'task-proxy': 'Proxy',
  'task-error-info': 'Error',
  'task-piece': 'Piece',
  'task-piece-length': 'Piece Size',
  'task-num-pieces': 'Pieces',
  'task-bittorrent-info': 'Torrent Info',
  'task-info-hash': 'Hash',
  'task-bittorrent-creation-date': 'Creation Date',
  'task-bittorrent-comment': 'Comment',
  'task-progress-info': 'Progress',
  'task-status': 'Status',
  'task-num-seeders': 'Seeders',
  'task-connections': 'Connections',
  'task-file-size': 'Size',
  'task-download-speed': 'Download Speed',
  'task-upload-speed': 'Upload Speed',
  'task-download-length': 'Downloaded',
  'task-upload-length': 'Uploaded',
  'task-ratio': 'Ratio',
  'task-peer-host': 'Host',
  'task-peer-ip': 'IP',
  'task-peer-client': 'Client',
  'navigate-to-downloading': 'Navigate to Downloading',
  'show-advanced-options': 'Advanced Options',
  'copyright-warning': 'Copyright Warning',
  'copyright-warning-message': 'The file you want to download may be copyrighted audio or video, please ensure that you have permission to access to it.',
  'copyright-yes': 'Yes, I have permission',
  'copyright-no': 'No, I don\'t have permission',
  'copyright-error-message': 'Failed to add task due to copyright issue',
  'pause-task-success': 'Successfully paused task "{{taskName}}"',
  'pause-task-fail': 'Failed to pause task "{{taskName}}"',
  'resume-task': 'Resume Task',
  'resume-task-success': 'Successfully resumed task "{{taskName}}"',
  'resume-task-fail': 'Failed to resume task "{{taskName}}"',
  'delete-task': 'Delete Task',
  'delete-selected-tasks': 'Delete Selected Tasks',
  'delete-task-confirm': 'Are you sure you want to remove download task "{{taskName}}"?',
  'batch-delete-task-confirm': 'Are you sure you want to remove {{count}} download tasks in batch?',
  'delete-task-label': 'Delete with Files',
  'delete-task-success': 'Successfully deleted task "{{taskName}}"',
  'delete-task-fail': 'Failed to delete task "{{taskName}}"',
  'remove-task-file-fail': 'Failed to delete task file(s), please delete them manually',
  'remove-task-config-file-fail': 'Failed to delete task config file, please delete it manually',
  'move-task-up': 'Move Task Up',
  'move-task-down': 'Move Task Down',
  'pause-all-task': 'Pause All Tasks',
  'pause-all-task-success': 'Successfully paused all tasks',
  'pause-all-task-fail': 'Failed to pause all tasks',
  'resume-all-task': 'Resume All Tasks',
  'resume-all-task-success': 'Successfully resumed all tasks',
  'resume-all-task-fail': 'Failed to resume all tasks',
  'select-all-task': 'Select All Tasks',
  'clear-recent-tasks': 'Clear Recent Tasks',
  'purge-record': 'Purge Task Record',
  'purge-record-success': 'Successfully purged task records',
  'purge-record-fail': 'Failed to purge task records',
  'batch-delete-task-success': 'Successfully delete tasks in batch',
  'batch-delete-task-fail': 'Failed to delete tasks in batch',
  'refresh-list': 'Refresh Task List',
  'no-task': 'There are no current tasks',
  'copy-link': 'Copy Link',
  'copy-link-success': 'Successfully copied link',
  'remove-record': 'Remove Task Record',
  'remove-record-confirm': 'Are you sure you want to remove download record for "{{taskName}}"?',
  'remove-record-label': 'Delete with Files',
  'remove-record-success': 'Successfully removed task record for "{{taskName}}"',
  'remove-record-fail': 'Failed to remove task record for "{{taskName}}"',
  'show-in-folder': 'Show Task In Folder',
  'file-not-exist': 'Target file does not exist or has been deleted',
  'file-path-error': 'File path error',
  'opening-task-message': 'Opening "{{taskName}}" ...',
  'get-task-name': 'Getting task name...',
  'remaining-prefix': 'Remaining',
  'select-torrent': 'Drag torrent file here, or click to select',
  'task-detail-title': 'Task Details',
  'task-info-dialog-title': '{{title}} Details',
  'download-start-message': 'Started downloading {{taskName}}',
  'download-pause-message': 'Paused downloading {{taskName}}',
  'download-stop-message': 'Stopped downloading {{taskName}}',
  'download-error-message': 'Error occurred when downloading {{taskName}}',
  'download-complete-message': 'Completed downloading {{taskName}}',
  'download-complete-notify': 'Download Completed',
  'bt-download-complete-message': 'Completed downloading {{taskName}}, seeding',
  'bt-download-complete-notify': 'BT Download Completed, seeding...',
  'bt-download-complete-tips': 'Tips: You can stop a task to end its seeding',
  'bt-stopping-seeding-tip': 'Stopping seeding, it will take some time to disconnect, please wait patiently',
  'download-fail-message': 'Failed to download {{taskName}}',
  'download-fail-notify': 'Download Failed'
}
