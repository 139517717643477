export default {
  'basic':'Основа',
  'advanced':'Разширени',
  'lab':'Лаборатория',
  'save': 'Съхронване и прилагане',
  'save-success-message':'настройките са запазени успешно',
  'save-fail-message': 'грешка при запазване на настройките',
  'discard': 'Отказ',
  'startup': 'стартиране',
  'open-at-login': 'стартиране на програмата заедно със стартирането на операционната система',
  'keep-window-state':'по време на затваряне на приложението, Запишете розмера и позицията на прозореца',
  'auto-resume-all':'автоматично възобновяване на всички недовършени задачи',
  'default-dir':'пея по подразбиране',
  'mas-default-dir-tips': 'поради ограничения в App Store, препоръчително е да зададете пътя по подразбиране като ~/Downloads',
  'transfer-settings':'скоростна кутия',
  'transfer-speed-upload': 'лимит на откат',
  'transfer-speed-download': 'лимит за изтегляне',
  'transfer-speed-unlimited':'Unlimited',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Запазете магнитната връзка като торент файл',
  'bt-auto-download-content': 'Автоматично изтегляне на магнит и торент съдържание',
  'bt-force-encryption': 'BT задължително криптиране',
  'keep-seeding': 'Продължавайте да засявате, докато не го спрете ръчно',
  'seed-ratio': 'Съотношение на семената',
  'seed-time': 'Време на семената',
  'seed-time-unit': 'минути',
  'task-manage':'мениджър на задачи',
  'max-concurrent-downloads': 'Максимум активни задачи',
  'max-connection-per-server': 'максимални връзки към сървъра',
  'new-task-show-downloading': 'автоматично показване на задача след добавяне',
  'no-confirm-before-delete-task': 'Не се изисква потвърждение преди изтриване на задача',
  'continue':'Продължи',
  'task-completed-notify': 'съобщение след изтеглянето',
  'auto-purge-record': 'автоматично почистване на записите за изтегляне след затваряне на приложението',
  'ui': 'UI',
  'appearance': 'външен вид',
  'theme-auto':'автоматично',
  'theme-light':'Светло',
  'theme-dark':'dark',
  'auto-hide-window': 'Автоматично отваряне на прозорци',
  'run-mode': 'Бягай като',
  'run-mode-standard': 'стандартно приложение',
  'run-mode-tray': 'Приложение в таванчето',
  'run-mode-hide-tray': 'Скриване на приложение в таванчето',
  'tray-speedometer': 'Таблата на лентата с менюта показва скорост в реално време',
  'language':'Език',
  'change-language': 'промяна на езика',
  'hide-app-menu': 'Скриване на менюто на приложението (само за Windows и Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'използване на Proxy',
  'proxy-bypass-input-tips': 'заобикаляне на настройките на прокси за тези хостове и домейни, един по един на ред',
  'proxy-scope-download': 'Изтегляне',
  'proxy-scope-update-app': 'Актуализиране на приложението',
  'proxy-scope-update-trackers': 'Актуализиране на трекери',
  'proxy-tips': 'преглед на ръководството за прокси',
  'bt-tracker':'Tracker сървър',
  'bt-tracker-input-tips': 'Tracker сървър, един на ред',
  'bt-tracker-tips': 'препоръчително:',
  'sync-tracker-tips':'Синхронизация',
  'auto-sync-tracker':'актуализиране на списъка с тракери всеки ден автоматично',
  'port':'портове за слушане',
  'bt-port':'пристанище на слушане BT',
  'dht-port':'DHT слушане Порт',
  'security':'сигурност',
  'rpc': 'RPC',
  'rpc-listen-port': 'RPC слушащ порт',
  'rpc-secret': 'RPC Secret',
  'rpc-secret-tips': 'Гледайте инструкцията RPC Secret',
  'developer':'developer',
  'Mock-user-agent':'оформление User-Agent',
  'aria2-conf-path': 'Вграден път за aria2.conf',
  'app-log-path': 'път към дневника на приложението',
  'download-session-path': 'качване на пътя на сесията',
  'factory-reset': 'Настройки по подразбиране',
  'factory-reset-confirm': 'Сигурни ли сте, че искате да се върнете към настройките по подразбиране?',
  'lab-warning': '️ ️ включването на функциите на лабораторията може да доведе до срив на приложението и загуба на данни, решете на свой риск!',
  'download-protocol':'протоколи',
  'protocols-default-client':'Задаване като клиент по подразбиране за следните протоколи',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Розширения',
  'baidu-exporter': 'BaiduExporter',
  'browser-extensions-tips': 'предоставени от общността,',
  'baidu-exporter-help': 'Кликнете тук, за да използвате',
  'auto-update':'автоматично обновяване',
  'auto-check-update':'автоматична проверка на актуализациите',
  'last-check-update-time': 'последната актуализация е проверена',
  'not-saved': 'Предпочитанията не са запазени',
  'not-saved-confirm': 'Променените предпочитания ще бъдат загубени, сигурни ли сте, че ще напуснете?'
}
