export default {
  'basic': 'De bază',
  'advanced': 'Avansate',
  'lab': 'Laborator',
  'save': 'Salvează şi Aplică',
  'save-success-message': 'Setările au fost salvate cu succes',
  'save-fail-message': 'Nu s-au putut salva setările',
  'discard': 'Renunţă',
  'startup': 'Pornire',
  'open-at-login': 'Deschide la logare',
  'keep-window-state': 'Păstrați dimensiunea și poziția ferestrei la închiderea aplicaţiei',
  'auto-resume-all': 'Reia automat toate sarcinile neterminate',
  'default-dir': 'Calea implicită',
  'mas-default-dir-tips': 'Datorită restricțiilor privind permisiunea sandbox-ului din App Store, se recomandă ca directorul de descărcare implicit să fie setat la ~/Downloads',
  'transfer-settings': 'Transmitere',
  'transfer-speed-upload': 'Limită upload',
  'transfer-speed-download': 'Limită download',
  'transfer-speed-unlimited': 'Nelimitat',
  'bt-settings': 'BitTorrent',
  'bt-save-metadata': 'Salvați linkul magnet ca fișier torrent',
  'bt-auto-download-content': 'Descărcați automat magnetul și conținutul torrent',
  'bt-force-encryption': 'Criptare forţată BT',
  'keep-seeding': 'Păstrați însămânțarea până când o opriți manual',
  'seed-ratio': 'Raportul semințelor',
  'seed-time': 'Timpul semințelor',
  'seed-time-unit': 'minute',
  'task-manage': 'Managementul sarcinilor',
  'max-concurrent-downloads': 'Sarcini active maxime',
  'max-connection-per-server': 'Conexiuni maxime per server',
  'new-task-show-downloading': 'Afișează automat descărcarea după adăugarea sarcinii',
  'no-confirm-before-delete-task': 'Nu este necesară confirmarea înainte de ștergerea sarcinii',
  'continue': 'Continuă',
  'task-completed-notify': 'Notifică după finalizarea sarcinii',
  'auto-purge-record': 'Ștergeți automat înregistrările de descărcare la ieșirea din aplicație',
  'ui': 'Interfață Utilizator',
  'appearance': 'Aspect',
  'theme-auto': 'Automat',
  'theme-light': 'Temă luminoasă',
  'theme-dark': 'Temă întunecată',
  'auto-hide-window': 'Ascunde automat fereastra',
  'run-mode': 'Ruleaza ca',
  'run-mode-standard': 'Aplicatie standard',
  'run-mode-tray': 'Aplicație pentru tava',
  'run-mode-hide-tray': 'Ascundeți aplicația tăvii',
  'tray-speedometer': 'Iconița din bară arată viteza în timp real',
  'show-progress-bar': 'Afișează bara de progres a descărcării',
  'language': 'Limba',
  'change-language': 'Schimbă limba',
  'hide-app-menu': 'Ascundeți meniul aplicației (numai Windows și Linux)',
  'proxy': 'Proxy',
  'enable-proxy': 'Activați Proxy',
  'proxy-bypass-input-tips': 'Ignorați setările proxy pentru aceste gazde și domenii, câte una pe fiecare linie',
  'proxy-scope-download': 'Descărcare',
  'proxy-scope-update-app': 'Actualizare aplicație',
  'proxy-scope-update-trackers': 'Actualizare Trackere',
  'proxy-tips': 'Vizualizați manualul proxy',
  'bt-tracker': 'Severe tracker (Torrent Tracker)',
  'bt-tracker-input-tips': 'Severe tracker, câte unul pe fiecare linie',
  'bt-tracker-tips': 'Recomandat: ',
  'sync-tracker-tips': 'Sincronizare',
  'auto-sync-tracker': 'Actualizați automat lista de servere tracker în fiecare zi',
  'port': 'Porturi pe care se ascultă',
  'bt-port': 'Port ascultare BT',
  'dht-port': 'Port ascultare DHT',
  'security': 'Securitate',
  'rpc': 'RPC',
  'rpc-listen-port': 'Portul de Ascultare RPC',
  'rpc-secret': 'Secret RPC',
  'rpc-secret-tips': 'Vizualizați manualul pentru secret RPC',
  'developer': 'Dezvoltator',
  'user-agent': 'User-Agent',
  'mock-user-agent': 'Mock User-Agent',
  'aria2-conf-path': 'Calea aria2.conf încorporată',
  'app-log-path': 'Calea jurnalului aplicației',
  'download-session-path': 'Calea sesiunii de download',
  'factory-reset': 'Resetare la setări din fabrică',
  'factory-reset-confirm': 'Sigur doriți să reveniți la setările din fabrică?',
  'lab-warning': '⚠️ Activarea funcțiilor de laborator poate duce la blocarea aplicației sau pierderea datelor, contiunați pe propriul risc!',
  'download-protocol': 'Protocoale',
  'protocols-default-client': 'Setați client implicit pentru următoarele protocoale',
  'protocols-magnet': 'Magnet [ magnet:// ]',
  'protocols-thunder': 'Thunder [ thunder:// ]',
  'browser-extensions': 'Extensii',
  'baidu-exporter': 'Exportator Baidu',
  'browser-extensions-tips': 'Furnizat de comunitate, ',
  'baidu-exporter-help': 'Faceți clic aici pentru instrucțiuni',
  'auto-update': 'Actualizare automată',
  'auto-check-update': 'Verificați automat dacă sunt disponibile actualizări',
  'last-check-update-time': 'Ultima dată când au fost verificate actualizările disponibile',
  'not-saved': 'Preferințele nu au fost salvate',
  'not-saved-confirm': 'Preferințele modificate se vor pierde, sunteți sigur că plecați?'
}
